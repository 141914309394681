import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Animate from "react-reveal";
import { Link } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import HeaderNavigation from "../../components/layout/HeaderNavigation";
import { scrollToTop } from "../../utils/scrollToTop";

const PrivacyPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="abstract-bg-1">
      <Helmet>
        <title>Utiva | Privacy Policy</title>
      </Helmet>

      <header className="relative pb-8 md:pb-28 header-with-abstract-1 text-center">
        <HeaderNavigation />
        <div className="pt-20 container mx-auto px-4">
          <Animate.Fade bottom>
            <p className="capitalize tracking-widest text-primary pb-4 text-base">
              Utiva
            </p>

            <h2 className="text-3xl md:text-5xl text-default font-mono font-semibold pb-4">
              Privacy Policy
            </h2>
          </Animate.Fade>
        </div>
      </header>

      <section className="pt-6 pb-10 w-full lg:w-8/12 mx-auto mb-10">
        <div className="container mx-auto px-4 leading-8 text-gray text-base">
          <Animate.Fade bottom>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Introduction
              </h3>
              <p>
                Utiva operates the app.utiva.io application, which provides the
                SERVICE.
              </p>
              <p>
                This page is used to inform website visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service, the Utiva
                website.
              </p>
              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation with this policy.
                The Personal Information that we collect are used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>
              <p>
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at
                app.utiva.io, unless otherwise defined in this Privacy Policy.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Information Collection and Use
              </h3>
              <p>
                For a better experience while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to your name, phone
                number, and postal address. The information that we collect will
                be used to contact or identify you.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Log Data
              </h3>
              <p>
                We want to inform you that whenever you visit our Service, we
                collect information that your browser sends to us that is called
                Log Data. This Log Data may include information such as your
                computer’s Internet Protocol ("IP") address, browser version,
                pages of our Service that you visit, the time and date of your
                visit, the time spent on those pages, and other statistics.
              </p>
            </div>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Cookies
              </h3>
              <p>
                Cookies are files with small amount of data that is commonly
                used an anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                computer’s hard drive.
              </p>

              <p>
                Our website uses these "cookies" to collection information and
                to improve our Service. You have the option to either accept or
                refuse these cookies, and know when a cookie is being sent to
                your computer. If you choose to refuse our cookies, you may not
                be able to use some portions of our Service.
              </p>
              <p>
                For more general information on cookies, please read{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://www.cookieconsent.com/what-are-cookies/"
                  target="_blank"
                >
                  "What Are Cookies"
                </a>
                .
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Service Providers
              </h3>
              <p>
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>

              <ul className="list-inside list-disc">
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>

              <p>
                We want to inform our Service users that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Security
              </h3>
              <p>
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Changes to This Privacy Policy
              </h3>
              <p>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately, after
                they are posted on this page.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Contact Us
              </h3>

              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to{" "}
                <Link
                  to="/contact-us"
                  className="text-default font-mono font-semibold"
                >
                  Contact Us
                </Link>
                .
              </p>
            </div>
          </Animate.Fade>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
