import { useEffect, useState } from "react";

const useSelectedLocation = () => {
  const location = localStorage.getItem("nativeLocation");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    setSelectedLocation(
      JSON.parse(localStorage.getItem("nativeLocation")) || null
    );
  }, [location]);

  return selectedLocation || undefined;
};

export default useSelectedLocation;
