import React from "react";

const SelectGroup = ({
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  className,
  error,
  options,
  title,
  ...props
}) => {
  return (
    <>
      <select
        className={`custom-select w-full border border-gray-2 text-primary rounded-lg text-sm sm:text-base h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 focus:outline-none focus:border focus:border-primary bg-temp-green-100 mb-2 ${
          className || ""
        }`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      >
        <option>Select {title}</option>
        {options}
      </select>
      <span className="text-sm text-red-600">{error}</span>
    </>
  );
};

export default SelectGroup;
