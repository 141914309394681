import axios from "../../axios/axios";

export const loginApi = async (payload) => {
  try {
    const response = await axios.post("auth/login", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerUserApi = async (payload) => {
  try {
    const response = await axios.post("auth/register", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordApi = async (payload) => {
  try {
    const response = await axios.post("auth/forgotpassword", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordApi = async (payload, token) => {
  try {
    const response = await axios.put(`auth/resetPassword/${token}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
