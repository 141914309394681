import React from "react";
import * as Animate from "react-reveal";
import OurPeopleImg1 from "../../assets/images/png/utiva_people/1.webp";
import OurPeopleImg2 from "../../assets/images/png/utiva_people/2.webp";
import OurPeopleImg3 from "../../assets/images/png/utiva_people/3.webp";

import OurPeopleImg4 from "../../assets/images/png/utiva_people/5.webp";
import OurPeopleImg5 from "../../assets/images/png/utiva_people/4.webp";
import OurPeopleImg6 from "../../assets/images/png/utiva_people/6.webp";
import OurPeopleImg7 from "../../assets/images/png/utiva_people/7.webp";

const OurPeopleSection = ({ content }) => {
  return (
    <section className=" md:py-8">
      <div className="flex flex-wrap justify-items-center items-center">
        <div className="flex w-full overflow-x-hidden lg:w-6/12 lg:pb-0">
          <Animate.Fade left>
            <img
              src={OurPeopleImg1}
              className="h-52 w-auto object-cover flex-shrink"
              alt=""
            />
            <img
              src={OurPeopleImg2}
              className="h-52 w-auto  object-cover flex-shrink"
              alt=""
            />
            <img
              src={OurPeopleImg3}
              className="h-52 w-auto  object-cover flex-shrink"
              alt=""
            />
          </Animate.Fade>
        </div>
        <div className="w-full lg:w-6/12 pb-8 order-2 lg:order-none pt-8 lg:pt-0">
          <Animate.Fade bottom>
            <p className="text-2xl w-11/12 md:w-9/12 lg:w-8/12 md:px-4 font-mono font-semibold  text-left md:text-center lg:text-left mx-auto lg:mx-0 text-default leading-relaxed">
              {content ||
                `Utiva is led by people who align with the vision and social mission
            of our existence.`}
            </p>
          </Animate.Fade>
        </div>

        <div className="flex justify-end w-full overflow-x-hidden pb-8">
          <Animate.Fade right>
            <img
              src={OurPeopleImg4}
              className="h-52 w-auto object-cover flex-shrink"
              alt=""
            />
            <img
              src={OurPeopleImg5}
              className="h-52 w-auto  object-cover flex-shrink"
              alt=""
            />
            <img
              src={OurPeopleImg6}
              className="h-52 w-auto  object-cover flex-shrink"
              alt=""
            />
            <img
              src={OurPeopleImg7}
              className="h-52 w-auto  object-cover flex-shrink"
              alt=""
            />{" "}
          </Animate.Fade>
        </div>
      </div>
    </section>
  );
};

export default OurPeopleSection;
