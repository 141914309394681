import React from "react";
import Logo2 from "../../../assets/images/svg/logo/utivawhite.svg";
import Logo from "../../../assets/images/svg/logo/utivacolored.svg";
import { Link, withRouter } from "react-router-dom";
import InputGroup from "../../../components/elements/InputGroup";
import Button from "../../../components/elements/Button";
import { registerUserApi } from "../../../store/actions";
import { useToasts } from "react-toast-notifications";
import store from "store";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import { Formik } from "formik";

const Register = ({ bgdark, ...props }) => {
  const { addToast } = useToasts();

  const initialState = {
    name: "",
    linkedInUrl: "",
    email: "",
    password: "",
    confirmPassword: "",
    files: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Full name is required";
    }

    if (!values.linkedInUrl) {
      errors.linkedInUrl = "Your LinkedIn profile link is required";
    }

    if (
      !values.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) ||
      !values.email?.includes("utiva.io")
    ) {
      errors.email = "Enter a valid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Password too short i.e minimum of 8";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }

    if (!values.files) {
      errors.files = "Upload your profile picture";
    }

    return errors;
  };

  return (
    <div>
      <header className="pb-8 md:pb-24 w-full relative">
        <div className="relative bg-transparent pt-4 pb-4 z-10">
          <div
            className={`container mx-auto px-4 ${
              bgdark ? "text-white" : "text-primary"
            }`}
          >
            <div className="flex justify-between items-center py-6 md:justify-start">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/login">
                  <span className="sr-only">Utiva</span>
                  <img
                    className="h-6 w-auto sm:h-8"
                    src={bgdark ? Logo2 : Logo}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto shadow-xl rounded-lg w-5/12 px-10 py-6">
        <div>
          <h3 className="text-primary text-2xl pb-8 font-bold">
            Create Account
          </h3>
          <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const formData = new FormData();
              formData.set("files", values.files);
              formData.append("name", values.name);
              formData.append("email", values.email);
              formData.append("linkedInUrl", values.linkedInUrl);
              formData.append("password", values.password);

              store.remove("utivav2_token");
              store.remove("utivav2_data");

              try {
                const response = await registerUserApi(formData);
                triggerSuccess(addToast, response?.message);

                store.set("utivav2_token", response.token);
                store.set("utivav2_data", response.user);
                props.history.push("/dashboard");
                setSubmitting(false);
              } catch (error) {
                triggerError(addToast, error);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="w-full mb-6">
                  <InputGroup
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name && errors.name}
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email && errors.email}
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="text"
                    placeholder="LinkedIn Profile Url"
                    name="linkedInUrl"
                    value={values.linkedInUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.linkedInUrl &&
                      touched.linkedInUrl &&
                      errors.linkedInUrl
                    }
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.password && touched.password && errors.password
                    }
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="file"
                    placeholder="Profile Picture"
                    name="files"
                    onChange={(e) => {
                      setFieldValue("files", e.target.files[0]);
                    }}
                    onBlur={handleBlur}
                    error={errors.files && touched.files && errors.files}
                    accept="image/png, image/jpeg"
                  />
                </div>

                <div className="w-full mb-8 text-right">
                  <Button
                    disabled={isSubmitting}
                    text={isSubmitting ? "Signing in..." : "Submit"}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default withRouter(Register);
