import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import store from "store";
import Logo from "../../../assets/images/svg/logo/utivacolored.svg";
import PowerButton from "../../../assets/images/svg/power-button.svg";
import { triggerSuccess } from "../../../components/alerts";

const AdminMainWrapper = (props) => {
  const { addToast } = useToasts();

  const handleLogout = () => {
    store.remove("utivav2_token");
    store.remove("utivav2_data");

    triggerSuccess(addToast, "Logout Successful!");

    props.history.push("/");
  };

  useEffect(() => {
    if (!store.get("utivav2_token")) {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="relative h-full min-h-screen w-full"
      style={{ background: "#F4F7FC" }}
    >
      <header className="pb-4 w-full relative">
        <div className="relative bg-transparent pt-4 pb-4 z-10">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center py-6 md:justify-start">
              <div className="flex justify-between w-full lg:flex-1">
                <Link to="/dashboard">
                  <span className="sr-only">Utiva</span>
                  <img className="h-6 w-auto sm:h-8" src={Logo} alt="" />
                </Link>

                <button
                  className="inline-flex items-center text-red-600 focus:outline-none"
                  onClick={handleLogout}
                >
                  <p className="mr-3">Logout</p>
                  <img src={PowerButton} className="h-7" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4">{props.children}</main>

      <footer className="text-sm text-gray mx-auto text-center container px-4 pt-20 pb-14">
        <p>© {new Date().getFullYear()} Utiva. All right reserved.</p>
      </footer>
    </div>
  );
};

export default withRouter(AdminMainWrapper);
