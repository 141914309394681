import React from "react";

export const ClockIcon = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 0C2.46735 0 0 2.46735 0 5.5C0 8.53265 2.46735 11 5.5 11C8.53265 11 11 8.53265 11 5.5C11 2.46735 8.53265 0 5.5 0ZM5.5 10.3125C2.84634 10.3125 0.687503 8.15366 0.687503 5.5C0.687503 2.84634 2.84634 0.687503 5.5 0.687503C8.15366 0.687503 10.3125 2.84634 10.3125 5.5C10.3125 8.15366 8.15366 10.3125 5.5 10.3125Z"
        fill="black"
      />
      <path
        d="M5.84375 2.0625H5.15625V5.64233L7.31945 7.80553L7.80554 7.31944L5.84375 5.35766V2.0625Z"
        fill="black"
      />
    </svg>
  );
};
