import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Animate from "react-reveal";
import { Link } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import HeaderNavigation from "../../components/layout/HeaderNavigation";
import { scrollToTop } from "../../utils/scrollToTop";

const RefundPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="abstract-bg-1">
      <Helmet>
        <title>Utiva | Refund Policy</title>
      </Helmet>

      <header className="relative pb-8 md:pb-28 header-with-abstract-1 text-center">
        <HeaderNavigation />
        <div className="pt-20 container mx-auto px-4">
          <Animate.Fade bottom>
            <p className="capitalize tracking-widest text-primary pb-4 text-base">
              Utiva
            </p>

            <h2 className="text-3xl md:text-5xl font-mono text-default font-semibold pb-4">
              Refund Policy
            </h2>
          </Animate.Fade>
        </div>
      </header>

      <section className="pt-6 pb-10 w-full lg:w-8/12 mx-auto mb-10">
        <div className="container mx-auto px-4 leading-8 text-gray text-base">
          <Animate.Fade bottom>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Refund, Cancellation, and Deferment Policy
              </h3>

              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Introduction
              </h3>
              <p>
                This page is used to inform website visitors, Utiva learners and
                other training partners who are enrolled in any of the Utiva
                training programs (virtual or physical training) about our
                refund, cancellation, and deferment policy.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Program and Training Cancellation
              </h3>
              <p>
                By completing your registration for a course and making a
                payment of full or installment fee, you agree to the following
                terms and conditions: By completing your registration for a
                course and making a payment of full or installment fee, you
                agree to the following Terms and Conditions:
                <br />
              </p>

              <ul className="list-inside list-disc">
                <li>
                  You have the right to cancel your class by requesting “course
                  cancellation” 5 working days before the course start date.
                  Course start dates are publicly published on our website, and
                  made available to all students.
                </li>
                <li>
                  Students who pay for a course late(that is lesser than 5 days
                  before the start date) cannot cancel their course once the
                  course's cohort has started.
                </li>
                <li>
                  Utiva reserves the right to reschedule a course, which will be
                  duly communicated within 2 working days of the scheduled date
                  before the commencement of the class.
                </li>
                <li>
                  Cancellation of a course{" "}
                  <span className="text-semibold">AFTER</span> the program and
                  training has started will attract NO refund, and students will
                  be obliged to complete the training program which has been
                  started.
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Changing Course
              </h3>
              <p>
                <ul className="list-inside list-disc">
                  <li>
                    Students can only change an already started course with
                    Utiva Academic team's approval.
                  </li>
                  <li>
                    The administrative fee for changing the course is 5%. The
                    administrative fee only applies after the course has
                    started.
                  </li>
                  <li>
                    If the course has not started, the student can only request
                    for a change of program 5 days before the start date.
                  </li>
                  <li>
                    Approval can only be granted 1 day before the training
                    starts.
                  </li>
                  <li>
                    When approval is granted, the new course has to be paid for
                    in full before the finance team can process the refund for
                    the previously enrolled course.
                  </li>
                </ul>
              </p>
            </div>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Refund Procedure
              </h3>
              <p>
                <ul className="list-inside list-disc">
                  <li>
                    In order to obtain a refund, learners must request a refund
                    in writing via email.
                  </li>
                  <li>
                    After a request for a refund has been made (with reasons),
                    the learner will receive an email with a refund form
                    included, where they will be required to fill in a few
                    details for their refund to be processed.
                  </li>
                  <li>
                    Refund is only approved when requested 5 days before the
                    course starts date
                  </li>
                  <li>
                    Refunds for course cancellation will take 10-15 working days
                    to process.
                  </li>
                  <li>
                    Refunds on erroneous or overpayments will take 5 working
                    days to process.
                  </li>
                  <li>
                    You need to send your payment information to
                    academy@utiva.io.
                  </li>
                </ul>
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Program Deferment
              </h3>
              <p>
                <ul className="list-inside list-disc">
                  <li>
                    A student who for some reason(s) is unable to begin a
                    program as at when due and wants to defer to the next cohort
                    can defer.
                  </li>
                  <li>
                    A student can only defer to the NEXT cohort. For example, if
                    you sign up for the Product Management October cohort and
                    cannot take the program in October, you can only defer your
                    training to the November cohort and not beyond November.
                    Deferment to any other cohort beyond the next cohort is NOT
                    allowed, and a refund WILL NOT be processed for such a
                    situation, and the student will forfeit the amount paid for
                    that program.
                  </li>
                </ul>
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Program Access
              </h3>
              <p>
                <ul className="list-inside list-disc">
                  <li>
                    A student is only allowed to be on the Utiva learning
                    platform for 1 year after completion of the program. All
                    rights and ACCESS to the platform will be removed after the
                    stipulated duration of 1 year
                  </li>
                  <li>
                    Under the condition, a student requests for refund or
                    program cancellation once the class has started, they will
                    be removed from the learning platform and will no longer
                    have access to the content.
                  </li>
                </ul>
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Contact Us
              </h3>

              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to{" "}
                <Link
                  to="/contact-us"
                  className="text-primary font-mono font-semibold"
                >
                  Contact Us
                </Link>
                .
              </p>
            </div>
          </Animate.Fade>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default RefundPolicy;
