export const schoolsMenuData = [
  {
    id: 1,
    url: "/school/data-school",
    title: "Data",
    desc: "Become a Data Analyst through our learning program.",
  },
  {
    id: 2,
    url: "/courses/design-bootcamp",
    title: "Design",
    desc: "A blend of in-class and virtual apprenticeship.",
  },
  {
    id: 3,
    url: "/courses/product-management-bootcamp",
    title: "Product",
    desc: "This course is co-created with leading tech firms.",
  },
  {
    id: 4,
    url: "/courses/cloud-bootcamp",
    title: "Cloud Computing",
    desc: "A collaborative learning for cloud enthusiasts",
  },
  {
    id: 10,
    url: "/courses/blockchain-developer-bootcamp",
    title: "Blockchain Developer",
    desc:
      "Explore practise and knowledge that makes a great blockchain developers.",
  },
  {
    id: 6,
    url: "/school/programming-school",
    title: "Programming",
    desc: "Coding school designed through virtual classes.",
  },
  {
    id: 7,
    url: "/courses/product-marketing-bootcamp",
    title: "Product Marketing",
    desc: "This course is co-created with leading marketing firms.",
  },
  {
    id: 8,
    url: "/courses/business-analysis-bootcamp",
    title: "Business Analysis",
    desc: "Explore practise and knowledge that makes a great business analyst.",
  },
  {
    id: 8,
    url: "/courses/cybersecurity-bootcamp",
    title: "Cybersecurity",
    desc:
      "Explore practise and knowledge that makes a great cybersecurity expert.",
  },
];
