import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as Animate from "react-reveal";
import { withRouter } from "react-router";
import EmptyImage from "../../assets/images/svg/safety-box.svg";
import Avatar from "../../assets/images/svg/user.svg";
import Footer from "../../components/layout/Footer";
import HeaderNavigation from "../../components/layout/HeaderNavigation";
import { getSingleBlogsApi } from "../../store/actions";
import { convertHTMLStringToHTML } from "../../utils/convertHTMLStringToHTML";
import { scrollToTop } from "../../utils/scrollToTop";

const BlogDetails = (props) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    scrollToTop();

    const id = props.location.pathname?.slice(6);
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (eventId) => {
    setLoading(true);
    try {
      const response = await getSingleBlogsApi(eventId);
      setDetails(response.data);
      setLoading(false);
    } catch (error) {
      setError("Network Error! Kindly refresh the page.");
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Utiva | Blog Details</title>
      </Helmet>

      <header className="">
        <HeaderNavigation />
      </header>

      <main className="mt-12">
        {loading ? (
          <div className="flex flex-col container mx-auto px-4 pb-12 md:pb-24 w-full md:w-10/12 lg:w-8/12 relative mb-10">
            <p className="mb-6 h-2 w-5/12 bg-black opacity-10 text-default"></p>

            <div className="mb-6 w-full md:w-5/12 rounded-lg bg-black opacity-10"></div>

            <div className="mb-6 h-12 bg-black opacity-10"></div>

            <div className="inline-flex items-center">
              <div className="w-10 h-10 lg:h-12 lg:w-12 rounded-full bg-black opacity-10 mr-3"></div>

              <p className="h-2 w-9/12 bg-black opacity-10"></p>
            </div>

            <div className="w-full rounded-lg mt-8 bg-black opacity-10 h-96"></div>
            <div className="mt-12 text-default space-y-4">
              <p className="h-2 w-full bg-black opacity-10"></p>
              <p className="h-2 w-10/12 bg-black opacity-10"></p>
              <p className="h-2 w-9/12 bg-black opacity-10"></p>
              <p className="h-2 w-10/12 bg-black opacity-10"></p>
              <p className="h-2 w-full bg-black opacity-10"></p>
            </div>
          </div>
        ) : error ? (
          <div className="mx-auto w-full relative py-20">
            <img src={EmptyImage} alt="" className="h-20 mx-auto" />
            <p className="text-default text-center mt-6">{error}</p>
          </div>
        ) : (
          <div className=" container mx-auto px-4 pb-12 md:pb-24 w-full md:w-10/12 lg:w-8/12 relative mb-10">
            <Animate.Fade bottom>
              <p className="text-sm lg:text-sm-15 text-default mb-6">
                {new Date(details?.createdAt).toDateString()}
              </p>

              <h2 className="mb-6 text-primary font-semibold text-xl font-mono lg:text-2xl xl:text-2xl lg:w-9/12 leading-relaxed">
                {details?.title}
              </h2>

              <a
                href={details?.user?.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="inline-flex items-center">
                  <div className="w-10 h-10 lg:h-12 lg:w-12 rounded-full mr-3">
                    <img
                      src={Avatar}
                      className="object-cover w-10 h-10 lg:h-12 lg:w-12 rounded-full"
                      alt=""
                    />
                  </div>

                  <p className="text-gray text-sm lg:text-sm-15">
                    By {details?.user?.name}
                  </p>
                </div>
              </a>

              <div className="w-full rounded-lg mt-8 ">
                <img
                  src={details?.contentHeaderImage}
                  className="object-cover w-full rounded-lg"
                  alt=""
                />

                {/* Convert String HTML TO HTML  */}
                <div
                  className="mt-12 text-default text-sm-15 lg:text-base"
                  dangerouslySetInnerHTML={convertHTMLStringToHTML(
                    details?.content
                  )}
                ></div>
              </div>
            </Animate.Fade>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default withRouter(BlogDetails);
