import axios from "../../axios/axios";

export const getStoriesApi = async (params) => {
  try {
    const response = await axios.get("stories", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleStoryApi = async (id) => {
  try {
    const response = await axios.get(`stories/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createStoriesApi = async (payload) => {
  try {
    const response = await axios.post("stories", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStorysApi = async (id, payload) => {
  try {
    const response = await axios.put(`stories/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStoryApi = async (blogId) => {
  try {
    const response = await axios.delete(`stories/${blogId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
