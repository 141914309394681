import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import DeleteModal from "../../../components/modals/DeleteModal";
import { deleteBlogsApi, getBlogsApi } from "../../../store/actions";
import AdminMainWrapper from "../layouts/AdminMainWrapper";
import AdminBlogDetailsModal from "./AdminBlogDetailsModal";
import AdminEditBlogModal from "./AdminEditBlogModal";
import CreateBlogModal from "./CreateBlogModal";

const AdminBlogs = () => {
  const { addToast } = useToasts();

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Article Category",
      selector: "category",
      sortable: true,
    },

    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <div>{new Date(row.createdAt)?.toDateString()}</div>,
    },

    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <div className="flex">
          <button
            className="focus:outline-none text-blue mr-3"
            onClick={() => handleShowEditModal(row)}
          >
            Edit
          </button>
          <button
            className="focus:outline-none text-red-600"
            onClick={() => handleShowDeleteModal(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setpageLimit] = useState(10);

  const [selectedRow, setSelectedRow] = useState("");
  const [showModal, setShowModal] = useState(false);

  const fetchData = async (page, limit) => {
    setLoading(true);
    try {
      const params = {
        page,
        limit,
      };

      const response = await getBlogsApi(params);
      setBlogs(response.data);
      setTotalRows(response.count);
      setLoading(false);
    } catch (error) {
      triggerError(addToast, error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, pageLimit);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchData(page, newPerPage);
    setpageLimit(newPerPage);
  };

  const handleViewSelectedRow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedRow("");
    setShowModal(false);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleShowCreateBlogModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedRow("");
    setShowEditModal(false);
  };

  const handleUpdateData = (id, data) => {
    setBlogs(blogs.map((a) => (a._id === id ? data : a)));
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleShowDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedRow("");
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await deleteBlogsApi(selectedRow?._id);
      triggerSuccess(addToast, response?.message);
      setDeleteLoading(false);

      setBlogs(blogs.filter((a) => a._id !== selectedRow?._id));

      handleCloseDeleteModal();
    } catch (error) {
      triggerError(addToast, error);
      setDeleteLoading(false);
    }
  };

  return (
    <AdminMainWrapper>
      <div className="">
        <p className="text-gray-2 text-sm-15 font-">
          <Link to="/dashboard">Dashboard</Link> &gt;{" "}
          <span className="text-gray font-semibold">Blogs</span>
        </p>

        <div className="pt-6 flex flex-1 justify-between flex-row items-center">
          <h3 className="text-primary font-bold text-2xl">Blog Management</h3>

          <button
            onClick={handleShowCreateBlogModal}
            className="text-white text-sm-15 bg-secondary font-semibold rounded-lg px-6 py-3 focus:outline-none"
          >
            Add New Article
          </button>
        </div>

        <div className="mt-8 rounded-xl">
          <DataTable
            noHeader={true}
            columns={columns}
            data={blogs}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            progressComponent={<div className="py-32 h4">Loading...</div>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleViewSelectedRow}
          />
        </div>
      </div>

      {showModal && (
        <AdminBlogDetailsModal
          show={showModal}
          handleModalClose={handleModalClose}
          data={selectedRow}
        />
      )}

      {showCreateModal && (
        <CreateBlogModal
          show={showCreateModal}
          handleModalClose={() => setShowCreateModal(false)}
          handleFetchNewBlogData={fetchData}
        />
      )}

      {showEditModal && (
        <AdminEditBlogModal
          show={showEditModal}
          handleModalClose={handleCloseEditModal}
          data={selectedRow}
          handleUpdateData={handleUpdateData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleModalClose={handleCloseDeleteModal}
          title="Article"
          deleteLoading={deleteLoading}
          handleDelete={handleDelete}
        />
      )}
    </AdminMainWrapper>
  );
};

export default AdminBlogs;
