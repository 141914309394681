import axios from "../../axios/axios";

export const getBlogsApi = async (params) => {
  try {
    const response = await axios.get("blogs", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleBlogsApi = async (blogId) => {
  try {
    const response = await axios.get(`blogs/${blogId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBlogsApi = async (payload) => {
  try {
    const response = await axios.post("blogs", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBlogsApi = async (blogId, payload) => {
  try {
    const response = await axios.put(`blogs/${blogId}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBlogsApi = async (blogId) => {
  try {
    const response = await axios.delete(`blogs/${blogId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
