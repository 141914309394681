import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CustomEditor = ({ name, value, error, onBlur, onChange }) => {
  return (
    <>
      <CKEditor
        id={name}
        editor={ClassicEditor}
        data={value}
        onChange={onChange}
      />
      <span className="text-red-600 text-sm">{error}</span>
    </>
  );
};

export default CustomEditor;
