import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import DeleteModal from "../../../components/modals/DeleteModal";
import { deleteEventsApi, getEventsApi } from "../../../store/actions";
import AdminMainWrapper from "../layouts/AdminMainWrapper";
import AdminAddNewEventModal from "./AdminAddNewEventModal";
import AdminEditEventModal from "./AdminEditEventModal";
import AdminEventDetailsModal from "./AdminEventDetailsModal";

const AdminEvents = () => {
  const { addToast } = useToasts();

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Event Link",
      selector: "eventLink",
      sortable: true,
    },

    {
      name: "Event Category",
      selector: "eventCategory",
      sortable: true,
    },

    {
      name: "Event Type",
      selector: "eventType",
      sortable: true,
    },
    {
      name: "Start Date",
      selector: "eventStartDate",
      sortable: true,
      cell: (row) => (
        <div>{new Date(row.eventStartDate)?.toLocaleDateString()}</div>
      ),
    },
    {
      name: "End Date",
      selector: "eventEndDate",
      sortable: true,
      cell: (row) => (
        <div>{new Date(row.eventEndDate)?.toLocaleDateString()}</div>
      ),
    },

    {
      name: "Start Time",
      selector: "eventStartTime",
      sortable: true,
      cell: (row) => (
        <div>{new Date(row.eventStartTime)?.toLocaleTimeString()}</div>
      ),
    },
    {
      name: "End Time",
      selector: "eventEndTime",
      sortable: true,
      cell: (row) => (
        <div>{new Date(row.eventEndTime)?.toLocaleTimeString()}</div>
      ),
    },

    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <div className="flex">
          <button
            className="focus:outline-none text-blue mr-3"
            onClick={() => handleShowEditModal(row)}
          >
            Edit
          </button>
          <button
            className="focus:outline-none text-red-600"
            onClick={() => handleShowDeleteModal(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setpageLimit] = useState(10);

  const [selectedRow, setSelectedRow] = useState("");
  const [showModal, setShowModal] = useState(false);

  const fetchData = async (page, limit) => {
    setLoading(true);
    try {
      const params = {
        page,
        limit,
      };

      const response = await getEventsApi(params);
      setEvents(response.data);
      setTotalRows(response.count);
      setLoading(false);
    } catch (error) {
      triggerError(addToast, error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, pageLimit);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchData(page, newPerPage);
    setpageLimit(newPerPage);
  };

  const handleViewSelectedRow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedRow("");
    setShowModal(false);
  };

  const [addNewEventModal, setAddNewEventModal] = useState(false);
  const handleAddNewEventShowModal = () => {
    setAddNewEventModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedRow("");
    setShowEditModal(false);
  };

  const handleUpdateData = (id, data) => {
    setEvents(events.map((a) => (a._id === id ? data : a)));
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleShowDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedRow("");
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await deleteEventsApi(selectedRow?._id);
      triggerSuccess(addToast, response?.message);
      setDeleteLoading(false);

      setEvents(events.filter((a) => a._id !== selectedRow?._id));

      handleCloseDeleteModal();
    } catch (error) {
      triggerError(addToast, error);
      setDeleteLoading(false);
    }
  };

  return (
    <AdminMainWrapper>
      <div className="">
        <p className="text-gray-2 text-sm-15 font-">
          <Link to="/dashboard">Dashboard</Link> &gt;{" "}
          <span className="text-gray font-semibold">Events</span>
        </p>

        <div className="pt-6 flex flex-1 justify-between flex-row items-center">
          <h3 className="text-primary font-bold text-2xl">Utiva Events</h3>

          <button
            onClick={handleAddNewEventShowModal}
            className="text-white text-sm-15 bg-secondary font-semibold rounded-lg px-6 py-3 focus:outline-none"
          >
            Add New Event
          </button>
        </div>

        <div className="mt-8 rounded-xl">
          <DataTable
            noHeader={true}
            columns={columns}
            data={events}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            progressComponent={<div className="py-32 h4">Loading...</div>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleViewSelectedRow}
          />
        </div>
      </div>
      {showModal && (
        <AdminEventDetailsModal
          show={showModal}
          handleModalClose={handleModalClose}
          data={selectedRow}
        />
      )}

      {addNewEventModal && (
        <AdminAddNewEventModal
          show={addNewEventModal}
          handleModalClose={() => setAddNewEventModal(false)}
          handleFetchNewEventData={fetchData}
        />
      )}

      {showEditModal && (
        <AdminEditEventModal
          show={showEditModal}
          handleModalClose={handleCloseEditModal}
          data={selectedRow}
          handleUpdateData={handleUpdateData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleModalClose={handleCloseDeleteModal}
          title="Event"
          deleteLoading={deleteLoading}
          handleDelete={handleDelete}
        />
      )}
    </AdminMainWrapper>
  );
};

export default AdminEvents;
