import axios from "axios";
import store from "store";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.get("utivav2_token");
    config.headers["Content-Type"] = "application/json";

    if (typeof token !== "undefined") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const successHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const status = error.response["status"];
  if (status === 401) {
    store.clearAll();
    window.location.push("/");
    alert("Your session has expired! Kindly Login again");
  }
  return Promise.reject(error);
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => {
    return errorHandler(error);
  }
);

export default instance;
