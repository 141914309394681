import { getErrorMessage } from "../../helpers/errorMessage";

export const triggerError = (addToast, error) => {
  const message = getErrorMessage(error);
  addToast(message, { appearance: "error" });
};

export const triggerSuccess = (addToast, message) => {
  addToast(message, { appearance: "success" });
};
