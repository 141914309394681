import React from "react";
import { Transition } from "@headlessui/react";
import InputGroup from "../../../components/elements/InputGroup";
import { Formik } from "formik";
import SelectGroup from "../../../components/elements/SelectGroup";
import { createJobsApi } from "../../../store/actions";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import { useToasts } from "react-toast-notifications";

const AdminAddNewCareerModal = ({
  show,
  handleModalClose,
  handleFetchNewJobData,
  ...props
}) => {
  const { addToast } = useToasts();

  const initialState = {
    title: "",
    jobApplyUrl: "",
    jobWorkMode: "",
    jobType: "",
    companyName: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Job title is required";
    }

    if (!values.jobApplyUrl) {
      errors.jobApplyUrl = "Job application form link is required";
    }
    if (!values.jobWorkMode) {
      errors.jobWorkMode = "Job work mode link is required";
    }
    if (!values.jobType) {
      errors.jobType = "Job type link is required";
    }

    if (!values.companyName) {
      errors.companyName = "Company name is required";
    }

    return errors;
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Add New Job Opening
              </h3>
              <div className="space-y-6">
                <Formik
                  initialValues={initialState}
                  validate={validate}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const payload = {
                      ...values,
                    };
                    setSubmitting(true);
                    try {
                      const response = await createJobsApi(payload);
                      triggerSuccess(addToast, response?.message);
                      setSubmitting(false);
                      handleModalClose();
                      handleFetchNewJobData();
                    } catch (error) {
                      triggerError(addToast, error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form className="" onSubmit={handleSubmit}>
                      <div className="w-full mb-6">
                        <InputGroup
                          type="text"
                          placeholder="Title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.title && touched.title && errors.title}
                        />
                      </div>
                      <div className="w-full mb-6">
                        <InputGroup
                          type="text"
                          placeholder="Job Application Form Link"
                          name="jobApplyUrl"
                          value={values.jobApplyUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.jobApplyUrl &&
                            touched.jobApplyUrl &&
                            errors.jobApplyUrl
                          }
                        />
                      </div>
                      <div className="w-full mb-6">
                        <InputGroup
                          type="text"
                          placeholder="Company Name e.g Utiva"
                          name="companyName"
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.companyName &&
                            touched.companyName &&
                            errors.companyName
                          }
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                        <div className="w-full mb-6">
                          <SelectGroup
                            placeholder="Job Work Mode"
                            name="jobWorkMode"
                            value={values.jobWorkMode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.jobWorkMode &&
                              touched.jobWorkMode &&
                              errors.jobWorkMode
                            }
                            title="Job Work Mode"
                            options={
                              <>
                                <option value="Remote">Remote</option>
                                <option value="On Site">On Site</option>
                              </>
                            }
                          />
                        </div>
                        <div className="w-full mb-6">
                          <SelectGroup
                            placeholder="Job Type"
                            name="jobType"
                            value={values.jobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.jobType &&
                              touched.jobType &&
                              errors.jobType
                            }
                            title="Job Type"
                            options={
                              <>
                                <option value="Full Time">Full Time</option>
                                <option value="Part Time">Part Time</option>
                              </>
                            }
                          />
                        </div>
                      </div>

                      <div className="flex justify-end mb-3">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="py-3 px-10 bg-primary text-white rounded-md my-3 focus:outline-none mr-4"
                        >
                          {isSubmitting ? "Processing..." : "Submit"}
                        </button>
                        <button
                          type="button"
                          onClick={handleModalClose}
                          className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddNewCareerModal;
