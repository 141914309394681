// Convert the html text to html
function createMarkup(value) {
  return { __html: value };
}
export const convertHTMLStringToHTML = (value) => {
  return createMarkup(
    new DOMParser().parseFromString(value, "text/html").body.firstChild
      .textContent
  );
};
