import React, { useState } from "react";
import Uk from "../../assets/images/png/united-kingdom.png";
import FlagIcon from "../../assets/images/svg/flag.svg";

const Card = ({ logo, linkUrl }) => {
  return (
    <div className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12 flex justify-center items-center rounded-full object-cover transition-all ease-in-out shadow-2xl">
      <a
        target="_blank"
        rel="noopener noreferrer "
        href={linkUrl}
        className="object-cover w-full h-full"
      >
        {logo}
      </a>
    </div>
  );
};

const FixedWhatsappLink = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div
      onMouseLeave={handleClose}
      className="fixed bottom-6 lg:bottom-12 right-6 lg:right-10 flex flex-col space-y-4"
    >
      {show && (
        <div className="flex flex-col space-y-4">
          <Card
            logo={
              <img
                src={FlagIcon}
                alt="Nigeria flag"
                className="mx-auto w-full h-full object-cover"
              />
            }
            linkUrl={"https://wa.me/2348035152982"}
          />
          <Card
            logo={
              <img
                src={Uk}
                alt="Uk flag"
                className="mx-auto w-full h-full object-cover"
              />
            }
            linkUrl={"https://wa.me/447492589196"}
          />
        </div>
      )}

      <button
        type="button"
        onClick={() => setShow(!show)}
        className="relative focus:outline-none flex justify-center items-center rounded-full whatsapp-overlay transition-all ease-in-out shadow-2xl px-3 py-3"
      >
        <svg
          className="w-6 h-6 lg:h-8 lg:w-8"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2027 1.74375C9.08036 0.61875 7.58571 0 5.99732 0C2.71875 0 0.0508929 2.66786 0.0508929 5.94643C0.0508929 6.99375 0.324107 8.01696 0.84375 8.91964L0 12L3.15268 11.1723C4.02054 11.6464 4.99821 11.8955 5.99464 11.8955H5.99732C9.27321 11.8955 12 9.22768 12 5.94911C12 4.36071 11.325 2.86875 10.2027 1.74375V1.74375ZM5.99732 10.8938C5.10804 10.8938 4.2375 10.6554 3.47946 10.2054L3.3 10.0982L1.43036 10.5884L1.92857 8.76429L1.81071 8.57679C1.31518 7.78929 1.05536 6.88125 1.05536 5.94643C1.05536 3.22232 3.27321 1.00446 6 1.00446C7.32054 1.00446 8.56071 1.51875 9.49286 2.45357C10.425 3.38839 10.9982 4.62857 10.9955 5.94911C10.9955 8.67589 8.72143 10.8938 5.99732 10.8938V10.8938ZM8.70804 7.19196C8.56071 7.11696 7.82946 6.75804 7.69286 6.70982C7.55625 6.65893 7.45714 6.63482 7.35804 6.78482C7.25893 6.93482 6.975 7.26696 6.88661 7.36875C6.80089 7.46786 6.7125 7.48125 6.56518 7.40625C5.69196 6.96964 5.11875 6.62679 4.54286 5.63839C4.39018 5.37589 4.69554 5.39464 4.97946 4.82679C5.02768 4.72768 5.00357 4.64196 4.96607 4.56696C4.92857 4.49196 4.63125 3.76071 4.50804 3.46339C4.3875 3.17411 4.26429 3.21429 4.17321 3.20893C4.0875 3.20357 3.98839 3.20357 3.88929 3.20357C3.79018 3.20357 3.62946 3.24107 3.49286 3.38839C3.35625 3.53839 2.97321 3.89732 2.97321 4.62857C2.97321 5.35982 3.50625 6.06696 3.57857 6.16607C3.65357 6.26518 4.62589 7.76518 6.11786 8.41071C7.06071 8.81786 7.43036 8.85268 7.90179 8.78304C8.18839 8.74018 8.78036 8.42411 8.90357 8.07589C9.02679 7.72768 9.02679 7.43036 8.98929 7.36875C8.95446 7.30179 8.85536 7.26429 8.70804 7.19196Z"
            fill="white"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default FixedWhatsappLink;
