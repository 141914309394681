import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.9"
        d="M16.1498 3.88222C16.1612 4.03581 16.1612 4.18944 16.1612 4.34303C16.1612 9.02784 12.4493 14.4258 5.66499 14.4258C3.57488 14.4258 1.63326 13.8443 0 12.835C0.296966 12.8679 0.582471 12.8789 0.890863 12.8789C2.61546 12.8789 4.20305 12.3193 5.47083 11.3648C3.84899 11.3319 2.48985 10.3115 2.02156 8.90717C2.25001 8.94007 2.47842 8.96202 2.71829 8.96202C3.0495 8.96202 3.38074 8.91812 3.6891 8.84135C1.99875 8.51218 0.730936 7.0859 0.730936 5.36338V5.31951C1.22204 5.58282 1.79315 5.74739 2.39844 5.76931C1.40478 5.13295 0.753788 4.04679 0.753788 2.81797C0.753788 2.15969 0.936496 1.55626 1.25631 1.02963C3.07232 3.18004 5.80203 4.58437 8.86293 4.73799C8.80583 4.47467 8.77155 4.20042 8.77155 3.92612C8.77155 1.97317 10.4162 0.382324 12.4607 0.382324C13.5228 0.382324 14.4822 0.810211 15.1561 1.50142C15.9898 1.34782 16.7893 1.05158 17.4975 0.645642C17.2233 1.46852 16.6409 2.15973 15.8756 2.59856C16.618 2.52179 17.3376 2.32426 18 2.05C17.4975 2.75215 16.8693 3.3775 16.1498 3.88222Z"
        fill="#050794"
      />
    </svg>
  );
};

export default TwitterIcon;
