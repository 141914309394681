export const enterpriseMenuData = [
  {
    id: 1,
    url: "/blog",
    title: "Blog",
  },
  {
    id: 2,
    url: "/events",
    title: "Events",
  },
  {
    id: 3,
    url: "/mc",
    title: "Masterclass",
  },
  {
    id: 4,
    url: "/corporate-training",
    title: "Consulting Practise",
  },
  {
    id: 5,
    url: "/scholarship",
    title: "Scholarship",
  },
  {
    id: 5,
    url: "/podcast",
    title: "Podcast",
  },
];

export const growData = [
  {
    id: 1,
    url: "/business",
    title: "Business",
  },
  {
    id: 2,
    url: "/hire",
    title: "Utiva Hire",
  },
  {
    id: 3,
    url: "https://impact.utiva.io",
    title: "Utiva Impact",
  },
  {
    id: 4,
    url: "https://utivakids.com/",
    title: "Utiva Kids",
  },
  {
    id: 5,
    url: "/nucleus",
    title: "The Nucleus",
  },
];
