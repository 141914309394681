import React from "react";
import HeaderNavigation from "../../../components/layout/HeaderNavigation";
import * as Animate from "react-reveal";

const ContactUsHeader = () => {
  return (
    <header className="pb-24 w-full">
      <HeaderNavigation />
      <div className="pt-4 md:pt-10 container mx-auto px-4">
        <div className="w-full md:10/12 lg:w-9/12 mx-auto text-center relative">
          <Animate.Fade bottom>
            <p className="font-light text-white bg-blue text-sm w-36 mx-auto rounded-full py-1 px-3 my-4">
              CONTACT US
            </p>
            <h2 className="w-full md:w-11/12 lg:w-9/12 mx-auto text-3xl lg:text-4xl font-mono leading-relaxed lg:leading-normal font-semibold text-primary py-6">
              We are here for your skill development Tell us what you need to
              grow.
            </h2>
          </Animate.Fade>
        </div>
      </div>
    </header>
  );
};

export default ContactUsHeader;
