import React from "react";
import * as Animate from "react-reveal";

import LearningIcon from "../../../assets/images/svg/intensive-learning.svg";
import BusinesIcon from "../../../assets/images/svg/business.svg";
import ProjectIcon from "../../../assets/images/svg/incubation.svg";

const Card = ({ icon, title }) => {
  return (
    <div className="py-6 px-6 bg-white rounded-lg text-center shadow-md border border-primary-light">
      <div className="pt-6 pb-10 relative flex justify-center">
        <div className="z-10">
          <img src={icon} alt="" />
        </div>
        <div
          className="absolute top-8 h-14 w-14 rounded-full "
          style={{ background: "#FFE89C" }}
        ></div>
      </div>
      <p className="text-default text-base pb-6">{title}</p>
    </div>
  );
};

const NucleusLearningApproach = () => {
  return (
    <section className="company-value-section py-10 md:py-16 bg-white mb-20">
      <div className="container mx-auto px-4">
        <div className="relative text-center mx-auto w-9/12 mb-4">
          <Animate.Fade bottom>
            <h4 className="text-base md:text-xl lg:text-2xl xl:text-3xl font-mono font-semibold text-primary">
              The Learning Approach
            </h4>
          </Animate.Fade>
        </div>

        <div className="py-12 relative">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 md:gap-12 lg:gap-20 w-full lg:w-10/12 mx-auto">
            <Animate.Fade bottom>
              <Card icon={LearningIcon} title="Short Video-Based Classes" />
              <Card icon={ProjectIcon} title="Capstone Projects" />
              <Card icon={BusinesIcon} title="Solve A Business Case" />
            </Animate.Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NucleusLearningApproach;
