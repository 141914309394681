import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import { useToasts } from "react-toast-notifications";
import { updateStorysApi } from "../../../store/actions/storiesActions";

const AdminEditJobModal = ({
  show,
  handleModalClose,
  data,
  handleUpdateData,
  ...props
}) => {
  const { addToast } = useToasts();

  const [initialState, setInitialState] = useState({
    approve: false,
  });

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Update Story
              </h3>

              <div className="space-y-6">
                <Formik
                  initialValues={initialState}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const payload = {
                      ...values,
                    };
                    setSubmitting(true);
                    try {
                      const response = await updateStorysApi(data._id, payload);
                      triggerSuccess(addToast, response?.message);
                      setSubmitting(false);
                      handleUpdateData(data._id, response?.data);
                      handleModalClose();
                    } catch (error) {
                      triggerError(addToast, error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form className="" onSubmit={handleSubmit}>
                      <div className="mb-10 flex space-x-4 mt-6">
                        <div>
                          <input
                            type="radio"
                            id="approve"
                            name="approve"
                            value={true}
                            onChange={handleChange}
                          />{" "}
                          <label htmlFor="approve"> Approve</label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="disapprove"
                            name="approve"
                            value={false}
                            onChange={handleChange}
                          />{" "}
                          <label htmlFor="disapprove"> Disapprove</label>
                        </div>
                      </div>

                      <div className="flex justify-end mb-3">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="py-3 px-10 bg-primary text-white rounded-md my-3 focus:outline-none mr-4"
                        >
                          {isSubmitting ? "Processing..." : "Update"}
                        </button>
                        <button
                          type="button"
                          onClick={handleModalClose}
                          className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditJobModal;
