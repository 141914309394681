import React from "react";
import { Transition } from "@headlessui/react";

const Row = ({ title, value }) => {
  return (
    <>
      <div className="">
        <p className="font-semibold text-default pb-3">{title}</p>
        <p className="text-gray">{value}</p>
      </div>
    </>
  );
};

const AdminCourseDetailsModal = ({
  show,
  handleModalClose,
  data,
  ...props
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Course Details
              </h3>
              <div className="space-y-6">
                <Row title="Course Title" value={data.title} />

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="Enrol Link"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data?.enrolLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data?.enrolLink}
                    </a>
                  }
                />

                {/* <Row
                  title="₦ (Outright Amount)"
                  value={data?.paymentInNaira?.outRightPaymentAmount}
                />
                <Row
                  title="₦ (Outright Link)"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data?.paymentInNaira?.outRightPaymentLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data?.paymentInNaira?.outRightPaymentLink}
                    </a>
                  }
                />

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="₦ (Part Payment Amount)"
                  value={data?.paymentInNaira?.partPaymentAmount}
                />
                <Row
                  title="₦ (Part Payment Upfront(%))"
                  value={data?.paymentInNaira?.partPaymentPercentageUpfront}
                />
                <Row
                  title="₦ (Part Payment Link)"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data?.paymentInNaira?.partPaymentLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data?.paymentInNaira?.outRightPaymentLink}
                    </a>
                  }
                /> */}

                {/* <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="$ (Outright Amount)"
                  value={data?.paymentInEuro?.outRightPaymentAmount}
                />
                <Row
                  title="$ (Outright Link)"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data?.paymentInEuro?.outRightPaymentLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data?.paymentInEuro?.outRightPaymentLink}
                    </a>
                  }
                />

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="$ (Part Payment Amount)"
                  value={data?.paymentInEuro?.partPaymentAmount}
                />
                <Row
                  title="$ (Part Payment Upfront(%))"
                  value={data?.paymentInEuro?.partPaymentPercentageUpfront}
                />
                <Row
                  title="$ (Part Payment Link)"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data?.paymentInEuro?.partPaymentLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data?.paymentInEuro?.outRightPaymentLink}
                    </a>
                  }
                /> */}

                <div className="flex justify-end mb-3">
                  <button
                    onClick={handleModalClose}
                    className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                  >
                    Close
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCourseDetailsModal;
