import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Animate from "react-reveal";
import Footer from "../../components/layout/Footer";
import HeaderNavigation from "../../components/layout/HeaderNavigation";
import { scrollToTop } from "../../utils/scrollToTop";

const TermsAndConditions = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="abstract-bg-1">
      <Helmet>
        <title>Utiva | Terms And Conditions</title>
      </Helmet>

      <header className="relative pb-8 md:pb-28 header-with-abstract-1 text-center">
        <HeaderNavigation />
        <div className="pt-20 container mx-auto px-4">
          <Animate.Fade bottom>
            <p className="capitalize tracking-widest text-primary pb-4 text-base">
              Utiva
            </p>

            <h2 className="text-3xl md:text-5xl text-default font-mono font-semibold pb-4">
              Terms And Conditions
            </h2>
          </Animate.Fade>
        </div>
      </header>

      <section className="pt-6 pb-10 w-full lg:w-8/12 mx-auto mb-10">
        <div className="container mx-auto px-4 leading-8 text-gray text-base">
          <Animate.Fade bottom>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Payment Policy
              </h3>
              <p>
                Unless otherwise agreed to in a private lending or financing
                agreement and as approved by Utiva, all students pay an upfront
                which is determined by the program lead of the student loan
                program.
              </p>
              <p>
                Students are required to pay the remaining balance in monthly
                instalments as stated by the chosen payment plan. For students
                based out of Nigeria, students are required to pay the remaining
                full balance within the specified agreed duration. Students are
                allowed to request a payment plan with the exception of 1-week
                courses. These payment plans must be approved during enrollment.
              </p>
              <p>
                If a student is partially paying for a course and a third-party
                is paying the remainder of the course, students can request to
                participate in a payment plan for their portion of course costs.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Courses Eligible
              </h3>
              <p>
                Utiva Study Loan is currently available for the following
                learning programs
                <br />
                Data School:
                <ul className="list-inside list-disc">
                  <li>Data Analytics Bootcamp</li>
                  <li>Agile HR Fellowship</li>
                  <li>Big Data with Python program</li>
                  <li>Product School</li>
                  <li>Design School</li>
                </ul>
              </p>
              <p>
                Note: If you are learning with Utiva as a beneficiary of a
                scholarship, the USL is not available to you. You are expected
                to make full payment on enrollment.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Student Eligibility
              </h3>
              <ul className="list-inside list-disc">
                <li className="pb-2">Nigerian (17 - 35 years old)</li>
                <li className="pb-2">
                  Secured admission into any Utiva School
                </li>
                <li className="pb-2">Have a low-income job of any sort</li>
              </ul>
            </div>
            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Payment Plans
              </h3>
              <p>
                We have payment plan options available for students interested
                in paying for their program in multiple instalments. To aid in
                your decision-making, we created this guide to help you
                determine which of our options best meets your needs.
              </p>

              <p>
                The standard loan comes with a 9% interest rate spread over 3
                calendar month which MUST be paid within the last week of every
                month.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Due Diligence
              </h3>
              <p>This contract is backed up with Legal Documents.</p>
              <p>
                You are granting the USL committee the access to reach out to
                your organization/employer and confirm your employment status
              </p>
              <p>
                Your enrollment is finalized ONLY if your application is
                successful.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Default Sanction
              </h3>
              <p>
                Utiva Inc. is liable to take up legal prosecution if you default
                on your repayment 2 consecutive months
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Loan Deferment/Forbearance
              </h3>
              <p>
                Under certain conditions, you can receive a deferment or
                forbearance that allows you to temporarily postpone or reduce
                your study loan payments. You’ll need to work with our USL team
                to apply for deferment or forbearance; be sure to keep making
                payments on your loan until the deferment or forbearance is in
                place.
              </p>
              <p>
                <strong>Note:</strong> If you don’t qualify for a deferment but
                are temporarily unable to make loan payments for such reasons as
                illness or financial hardship, we may grant you loan
                forbearance. With forbearance, you may be able to stop making
                payments or reduce your monthly payment. Interest will continue
                to accrue on your subsidized and unsubsidized loans (including
                all PLUS loans.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-default font-mono text-xl lg:text-2xl mb-4 font-semibold">
                Loan Cancellation
              </h3>

              <p>
                You may qualify to have some or all of your loan amount forgiven
                if you perform certain types of service (for example, teaching
                in a low-income school or working for certain types of public
                service organizations). In certain other circumstances, such as
                if you were unable to complete your program of study because the
                program was cancelled, if you become totally and permanently
                disabled (in accordance with our definition), or if you die,
                your obligation to repay your study loan may be discharged.
                Additional requirements apply to receive these benefits.
              </p>
            </div>
          </Animate.Fade>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
