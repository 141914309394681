import React from "react";
import * as Animate from "react-reveal";
import FlagIcon from "../../../assets/images/svg/flag.svg";
import Uk from "../../../assets/images/png/united-kingdom.png";
import Image from "../../../assets/images/png/nigeria.webp";
import UKImage from "../../../assets/images/png/ukimage.jpeg";

const ContactUsLocation = () => {
  return (
    <div className="pt-6 md:pt-20 pb-24  md::container mx-auto md:px-4">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="w-full relative">
          <img
            src={Image}
            className="w-full  min-h-screen md:min-h-0 md:h-full rounded-lg object-cover md:object-contain"
            alt=""
          />

          <div className="absolute -top-5  w-full text-center">
            <img src={FlagIcon} className="mx-auto" alt="flag" />

            <Animate.Fade bottom>
              <p className="text-gray-2 text-base md:text-lg mt-12 pb-2 md:pb-8">
                Transforming lives from Lagos, Nigeria
              </p>

              <p className="mt-4">
                <svg
                  className="mx-auto"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M14.3281 3.08241C13.2357 1.19719 11.2954 0.0454395 9.13767 0.00142383C9.04556 -0.00047461 8.95285 -0.00047461 8.86071 0.00142383C6.70303 0.0454395 4.76268 1.19719 3.67024 3.08241C2.5536 5.0094 2.52305 7.32409 3.5885 9.27424L8.05204 17.4441C8.05405 17.4477 8.05605 17.4513 8.05812 17.4549C8.25451 17.7963 8.60632 18 8.99926 18C9.39216 18 9.74397 17.7962 9.94032 17.4549C9.94239 17.4513 9.9444 17.4477 9.9464 17.4441L14.4099 9.27424C15.4753 7.32409 15.4448 5.0094 14.3281 3.08241ZM8.99919 8.15627C7.60345 8.15627 6.46794 7.02076 6.46794 5.62502C6.46794 4.22928 7.60345 3.09377 8.99919 3.09377C10.3949 3.09377 11.5304 4.22928 11.5304 5.62502C11.5304 7.02076 10.395 8.15627 8.99919 8.15627Z"
                      fill="#050794"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </p>

              <p className="text-primary md:text-lg mt-4">
                Plot 767 Somide Odujinrin, Omole Phase 2 Lagos
              </p>
            </Animate.Fade>
          </div>
        </div>

        <div className="w-full relative">
          <img
            src={UKImage}
            className="w-full min-h-screen md:min-h-0 md:h-full rounded-lg object-cover md:object-cover"
            alt=""
          />

          <div className="absolute -top-5  w-full text-center">
            <img src={Uk} className="mx-auto" alt="flag" />

            <Animate.Fade bottom>
              <p className="text-gray-2 md:text-lg mt-12 pb-2 md:pb-8">
                Transforming lives from London, UK
              </p>

              <p className="mt-4">
                <svg
                  className="mx-auto"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M14.3281 3.08241C13.2357 1.19719 11.2954 0.0454395 9.13767 0.00142383C9.04556 -0.00047461 8.95285 -0.00047461 8.86071 0.00142383C6.70303 0.0454395 4.76268 1.19719 3.67024 3.08241C2.5536 5.0094 2.52305 7.32409 3.5885 9.27424L8.05204 17.4441C8.05405 17.4477 8.05605 17.4513 8.05812 17.4549C8.25451 17.7963 8.60632 18 8.99926 18C9.39216 18 9.74397 17.7962 9.94032 17.4549C9.94239 17.4513 9.9444 17.4477 9.9464 17.4441L14.4099 9.27424C15.4753 7.32409 15.4448 5.0094 14.3281 3.08241ZM8.99919 8.15627C7.60345 8.15627 6.46794 7.02076 6.46794 5.62502C6.46794 4.22928 7.60345 3.09377 8.99919 3.09377C10.3949 3.09377 11.5304 4.22928 11.5304 5.62502C11.5304 7.02076 10.395 8.15627 8.99919 8.15627Z"
                      fill="#050794"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </p>

              <p className="text-primary md:text-lg mt-4">
                1 Polythenic street SE18 6PB
              </p>
            </Animate.Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsLocation;
