import React from "react";

export const BuildingIcon = ({ className }) => {
  return (
    <svg
      className={className}
      // width="31"
      // height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0918 22.3418H29.002V20.3438C29.002 19.8422 28.5953 19.4355 28.0938 19.4355C27.5922 19.4355 27.1855 19.8422 27.1855 20.3438V22.3418H24.1582V0.908203C24.1582 0.406633 23.7516 0 23.25 0H7.75C7.24843 0 6.8418 0.406633 6.8418 0.908203V22.3418H3.81445V20.3438C3.81445 19.8422 3.40782 19.4355 2.90625 19.4355C2.40468 19.4355 1.99805 19.8422 1.99805 20.3438V22.3418H0.908203C0.406633 22.3418 0 22.7484 0 23.25V30.0918C0 30.5934 0.406633 31 0.908203 31H30.0918C30.5934 31 31 30.5934 31 30.0918V23.25C31 22.7484 30.5934 22.3418 30.0918 22.3418ZM1.81641 24.1582H6.8418V29.1836H1.81641V24.1582ZM13.0176 29.1836V27.0645H17.9824V29.1836H13.0176ZM19.7988 29.1836V26.1562C19.7988 25.6547 19.3922 25.248 18.8906 25.248H12.1094C11.6078 25.248 11.2012 25.6547 11.2012 26.1562V29.1836H8.6582V1.81641H22.3418V29.1836H19.7988ZM29.1836 29.1836H24.1582V24.1582H29.1836V29.1836Z"
        fill="#0F078E"
      />
    </svg>
  );
};
