import { Transition } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { enterpriseMenuData, growData } from "../data/enterpriseMenuData";
import TestimonyLink from "../../blocks/TestimonyLink";
import ImpactIcon from "../../../assets/images/svg/intensive-learning.svg";
import NavDropdownTitle from "../../blocks/NavDropdownTitle";

const Arrow = () => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66227 6.19888C8.91611 5.94504 8.91611 5.53348 8.66227 5.27964L4.52569 1.14306C4.27185 0.889223 3.8603 0.889223 3.60646 1.14306C3.35262 1.3969 3.35262 1.80846 3.60646 2.0623L7.28341 5.73926L3.60646 9.41621C3.35262 9.67005 3.35262 10.0816 3.60646 10.3355C3.8603 10.5893 4.27185 10.5893 4.52569 10.3355L8.66227 6.19888ZM0.464844 6.38926H8.20265V5.08926H0.464844V6.38926Z"
      fill="#565656"
    />
  </svg>
);

const EnterpriseDropdown = ({ isEnterpriseOpen, handleClose }) => {
  return (
    <Transition
      show={isEnterpriseOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {(ref) => (
        <div className="absolute z-10 -ml-4 mt-3 text-left transform px-2 w-max sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl sm:px-0 lg:ml-0 lg:left-1/4 md:-translate-x-1/3 lg:-translate-x-1/2">
          <div
            onMouseLeave={handleClose}
            className="rounded-xl overflow-hidden mt-6 lg:mt-8"
            style={{ boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="bg-white px-6 py-6 sm:p-14 sm:pb-20">
              <NavDropdownTitle icon={ImpactIcon} title="Utiva for Corporate" />

              <div className="flex flex-wrap justify-between">
                <div className="w-7/12 grid grid-cols-2 gap-6 gap-y-8 sm:gap-y-14 gap-x-8 md:gap-x-28">
                  <div className="">
                    <p className="text-default font-bold mb-4 text-sm-15">
                      Grow with Utiva
                    </p>

                    <ul className="text-sm  space-y-4">
                      {growData?.map((menu) => (
                        <li className="text-gray hover:text-primary transition-all ease-in-out">
                          {menu?.url?.includes("http") ? (
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={menu?.url}
                              className="flex justify-between"
                            >
                              {menu?.title} <Arrow />
                            </a>
                          ) : (
                            <Link
                              to={menu?.url}
                              className="flex justify-between"
                            >
                              {menu?.title} <Arrow />
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="">
                    <p className="text-default font-bold mb-4 text-sm-15">
                      Impact
                    </p>

                    <ul className="text-sm  space-y-4">
                      {enterpriseMenuData?.map((menu) => (
                        <li className="text-gray hover:text-primary transition-all ease-in-out">
                          <Link to={menu?.url} className="">
                            {menu?.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="w-4/12">
                  <p className="text-default font-bold mb-4 text-sm-15">
                    Why learn at Utiva?
                  </p>
                  <p className="text-gray leading-6 text-sm">
                    We offer learning programs that are designed by industry
                    knowledge experts and co-created with leading companies.
                  </p>

                  <TestimonyLink
                    justifyClassName="lg:justify-start"
                    className={"mt-4 lg:mt-6 xl:mt-10"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default EnterpriseDropdown;
