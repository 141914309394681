import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import InputGroup from "../../../components/elements/InputGroup";
import TextAreaGroup from "../../../components/elements/TextAreaGroup";

const EnquiryFormModal = ({ show, onClose, handleCloseModal, ...props }) => {
  const [initialState, setInitialState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setInitialState({
      ...initialState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white py-5 sm:py-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="relative text-center px-5 sm:px-12">
                <button
                  onClick={handleCloseModal}
                  className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  pt-1 px-2 right-8 -top-10 focus:outline-none"
                >
                  x
                </button>
                <h2 className=" text-xl md:text-2xl text-default leading-10 pt-4">
                  Fill out the form below. One of our Digital Transformation
                  Leads will be in touch with you
                </h2>
                <div className="mt-12">
                  <form>
                    <div>
                      <div className="w-full flex flex-wrap justify-between text-left">
                        <div className="w-full lg:space-x-6 lg:inline-flex justify-between">
                          <div className="w-full lg:w-96">
                            <InputGroup
                              className="mb-6 border-primary-light-2"
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                              value={initialState.firstname}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="w-full lg:w-96">
                            <InputGroup
                              className="mb-6 border-primary-light-2"
                              type="text"
                              placeholder="Last Name"
                              name="lastname"
                              value={initialState.lastname}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="w-full lg:space-x-6 lg:inline-flex justify-between">
                          <div className="w-full lg:w-96">
                            <InputGroup
                              className="mb-6 border-primary-light-2"
                              type="email"
                              placeholder="Email Address"
                              name="email"
                              value={initialState.email}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="w-full lg:w-96">
                            <InputGroup
                              className="mb-6 border-primary-light-2"
                              type="tel"
                              placeholder="Phone Number"
                              name="phone"
                              value={initialState.phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="w-full">
                          <TextAreaGroup
                            placeholder="Message"
                            className="mb-6 border-primary-light-2"
                            marginBottom="mb-6"
                            label="Message *"
                            name="message"
                            value={initialState.message}
                            onChange={handleChange}
                            showTip={false}
                            rows={6}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-baseline justify-end flex-end">
                      <button
                        type="submit"
                        className="mb-4 py-3 px-12 text-sm bg-secondary text-white rounded-md focus:outline-none"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryFormModal;
