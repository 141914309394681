import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const useFBQPageView = () => {
  let location = useLocation();

  useEffect(() => {
    console.log("read");
    ReactPixel.pageView();
  }, [location]);

  return true;
};

export default useFBQPageView;
