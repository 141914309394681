import React from "react";
import { Helmet } from "react-helmet";
import OurPeopleSection from "../../components/blocks/OurPeopleSection";
import Footer from "../../components/layout/Footer";
import NucleusAbout from "./components/NucleusAbout";
import NucleusCTA from "./components/NucleusCTA";
import NucleusLearningApproach from "./components/NucleusLearningApproach";
import NucluesHeader from "./components/NucluesHeader";

const Nucleus = () => {
  return (
    <div>
      <Helmet>
        <title>Utiva | The Nucleus</title>
      </Helmet>

      <NucluesHeader />

      <NucleusAbout />

      <NucleusCTA />

      <NucleusLearningApproach />

      <div className="mb-12">
        <OurPeopleSection
          content={`
        Utiva is led by people who align with the vision and social mission of our existence.`}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Nucleus;
