import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import MainRoutes from "./routes";
import { ToastProvider } from "react-toast-notifications";
import ReactPixel from "react-facebook-pixel";

// css
import "./assets/css/tailwind.css";

const advancedMatching = {};

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};
ReactPixel.init("470036008223226", advancedMatching, options);

ReactDOM.render(
  <ToastProvider autoDismiss autoDismissTimeout={4000}>
    <MainRoutes />
  </ToastProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
