import React from "react";

const LinkedInIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.335 18.7653H15.67V14.5883C15.67 13.5923 15.65 12.3103 14.28 12.3103C12.891 12.3103 12.679 13.3943 12.679 14.5153V18.7653H10.013V10.1763H12.573V11.3463H12.608C12.966 10.6723 13.836 9.95927 15.136 9.95927C17.836 9.95927 18.336 11.7373 18.336 14.0503V18.7653H18.335ZM7.003 9.00127C6.79956 9.00153 6.59806 8.96164 6.41006 8.88388C6.22207 8.80611 6.05127 8.69201 5.90746 8.54811C5.76365 8.4042 5.64965 8.23333 5.57201 8.04528C5.49437 7.85724 5.4546 7.65571 5.455 7.45227C5.4552 7.1461 5.54618 6.84687 5.71644 6.59242C5.8867 6.33796 6.12859 6.1397 6.41153 6.02272C6.69447 5.90574 7.00574 5.87529 7.30598 5.93521C7.60622 5.99513 7.88196 6.14274 8.09831 6.35938C8.31466 6.57601 8.46191 6.85193 8.52145 7.15225C8.58099 7.45257 8.55013 7.7638 8.43278 8.04659C8.31543 8.32937 8.11687 8.57101 7.86219 8.74094C7.60751 8.91087 7.30817 9.00147 7.002 9.00127H7.003ZM8.339 18.7653H5.666V10.1763H8.34V18.7653H8.339ZM19.67 3.42627H4.329C3.593 3.42627 3 4.00627 3 4.72327V20.1293C3 20.8463 3.594 21.4263 4.328 21.4263H19.666C20.4 21.4263 21 20.8463 21 20.1293V4.72327C21 4.00627 20.4 3.42627 19.666 3.42627H19.669H19.67Z"
        fill="#050794"
      />
    </svg>
  );
};

export default LinkedInIcon;
