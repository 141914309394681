import React from "react";

const Button = ({ disabled, text, type }) => {
  return (
    <button
      type={type || "submit"}
      disabled={disabled}
      className="font-normal bg-secondary h-12 px-14 text-white rounded-lg focus:outline-none"
    >
      {text || "Send"}
    </button>
  );
};

export default Button;
