import React from "react";

const InputGroup = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  className,
  error,
  ...props
}) => {
  return (
    <>
      <input
        type={type || "text"}
        className={`w-full border border-gray-2 rounded-lg placeholder-gray-2 text-sm sm:text-base h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-primary focus:outline-none focus:border focus:border-primary bg-temp-green-100 mb-2 ${
          className || ""
        }`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <span className="text-sm text-red-600">{error}</span>
    </>
  );
};

export default InputGroup;
