import React from "react";

export const HamburgerIcon = () => {
  return (
    <svg
      width="25"
      height="12"
      viewBox="0 0 25 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="2" rx="1" fill="#050794" />
      <rect x="3" y="5" width="22" height="2" rx="1" fill="#050794" />
      <rect x="5" y="10" width="20" height="2" rx="1" fill="#050794" />
    </svg>
  );
};
