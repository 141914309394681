import React from "react";
import * as Animate from "react-reveal";
import AboutTrainerImage from "../../../assets/images/png/aboutTrainer.webp";

const AboutTipItem = ({ title }) => {
  return (
    <div className="flex flex-wrap items-start">
      <div className="h-10 w-10 px-3 py-4 text-center rounded-full checkmark-shadow mr-8">
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8174 0.573618C14.4576 0.226112 13.8872 0.226112 13.5275 0.573618L4.89963 9.20144L1.8423 6.14411C1.48609 5.77528 0.898298 5.76511 0.529522 6.12132C0.16069 6.47753 0.150518 7.06532 0.506728 7.4341C0.514181 7.44182 0.521797 7.44944 0.529522 7.45689L4.24324 11.1706C4.60576 11.5331 5.19349 11.5331 5.55601 11.1706L14.8403 1.88634C15.1965 1.51757 15.1863 0.929829 14.8174 0.573618Z"
            fill="#FBAF1B"
          />
        </svg>
      </div>
      <p className="md:pt-1 w-9/12 leading-7">{title}</p>
    </div>
  );
};

const NucleusAbout = () => {
  const handleScollTo = () => {
    document.querySelector("#orderNow").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <section className="pt-20 lg:pb-10">
      <div className="container mx-auto px-2 md:px-5 lg:px-8 pt-4  about-v-section">
        <div className="flex flex-wrap px-4 md:px-8 ">
          <div className="w-full lg:w-8/12 py-12 md:pt-16 lg:pt-20">
            <Animate.Fade left>
              <h2 className="font-semibold font-mono text-lg md:text-2xl lg:text-3xl text-default pb-8 w-full md:w-11/12 lg:w-8/12">
                Specially designed to help you grow your career OFFLINE
              </h2>
              <p className="leading-7 text-default w-full md:w-11/12 lg:w-9/12">
                ​​‘The Nucleus’, is packed with more than 40 curated learning
                content and allows Africans in the remote regions now access
                tech skill training. Learn Programming, Product Management,
                UI/UX Design, Data Analytics at your own convenience.
              </p>
              <button
                onClick={handleScollTo}
                className="mt-6 bg-secondary py-3 px-8 rounded-lg text-white focus:outline-none"
              >
                Order Now
              </button>
            </Animate.Fade>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-14 my-12">
              <Animate.Fade left>
                <AboutTipItem title="Self-paced so you can take your time." />
                <AboutTipItem title="HD Quality Videos." />

                <AboutTipItem title="Highly interactive learning methodology." />
                <AboutTipItem title="Business case-centric projects." />
              </Animate.Fade>
            </div>
          </div>
          <div className="w-full lg:w-4/12 lg:pt-4">
            <Animate.Fade right>
              <img src={AboutTrainerImage} alt="" />
            </Animate.Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NucleusAbout;
