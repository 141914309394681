import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import OurPeopleSection from "../../components/blocks/OurPeopleSection";
import Footer from "../../components/layout/Footer";
import ContactUsHeader from "./components/ContactUsHeader";

import { scrollToTop } from "../../utils/scrollToTop";
import ContactUsLocation from "./components/ContactUsLocation";
import ContactUsOptions from "./components/ContactUsOptions";
import EnquiryFormModal from "./components/EnquiryFormModal";

const ContactUs = () => {
  const [showEnquiryFormModal, setShowEnquiryFormModal] = useState(false);

  const handleOnClick = () => {
    setShowEnquiryFormModal(true);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Utiva | Contact Us</title>
      </Helmet>

      <ContactUsHeader />

      <ContactUsOptions onClick={handleOnClick} />

      <ContactUsLocation />

      <div className="mb-12">
        <OurPeopleSection
          content={`
        Utiva is led by people who align with the vision and social mission of our existence.`}
        />
      </div>
      <Footer />

      {showEnquiryFormModal && (
        <EnquiryFormModal
          show={showEnquiryFormModal}
          handleCloseModal={() => setShowEnquiryFormModal(false)}
        />
      )}
    </div>
  );
};

export default ContactUs;
