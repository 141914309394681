export const aboutMenuData = [
  {
    id: 1,
    url: "/about-us",
    title: "About Us",
  },
  {
    id: 2,
    url: "/careers",
    title: "Career",
  },
  {
    id: 7,
    url: "/alumni-stories",
    title: "Alumni Stories",
  },
  {
    id: 5,
    url: "/stories",
    title: "Student Stories",
  },
  {
    id: 3,
    url: "/be-a-trainer",
    title: "Be A Trainer",
  },
  {
    id: 4,
    url: "/partners",
    title: "Our Partners",
  },
  {
    id: 6,
    url: "/ubn",
    title: "Utiva Business Network",
  },
];

export const reportsData = [
  {
    id: 1,
    url:
      "https://drive.google.com/file/d/1zvAztVMsgGulve7GsmLMjVLL7Dmh_4uZ/view?usp=sharing",
    title: "Download 2020 Report",
  },
  {
    id: 2,
    url:
      "https://drive.google.com/file/d/1Drk48d5sl4K2vk6LEWLQ-0M1Ogwz4zlO/view?usp=sharing",
    title: "Download 2019 Report",
  },
];
