import axios from "../../axios/axios";

export const getCohortsApi = async (params) => {
  try {
    const response = await axios.get("cohorts", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCohortsApi = async (payload) => {
  try {
    const response = await axios.post("cohorts", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCohortsApi = async (courseId, payload) => {
  try {
    const response = await axios.put(`cohorts/${courseId}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
