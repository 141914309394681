import React from "react";
import { Transition } from "@headlessui/react";
import InputGroup from "../../../components/elements/InputGroup";
import { Formik } from "formik";
import { createCoursesApi } from "../../../store/actions";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import { useToasts } from "react-toast-notifications";

const AdminAddNewCourseModal = ({
  show,
  handleModalClose,
  handleFetchData,
  ...props
}) => {
  const { addToast } = useToasts();

  const initialState = {
    title: "",
    enrolLink: "",
    paymentInNaira: {
      outRightPaymentAmount: "",
      outRightPaymentLink: "",
      partPaymentPercentageUpfront: "",
      partPaymentAmount: "",
      partPaymentLink: "",
    },
    paymentInEuro: {
      outRightPaymentAmount: "",
      outRightPaymentLink: "",
      partPaymentPercentageUpfront: "",
      partPaymentAmount: "",
      partPaymentLink: "",
    },
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Course title is required";
    }

    return errors;
  };

  const handleInputChange = (e, name, values, setValues) => {
    e.preventDefault();
    const entry = values[name];
    entry[e.target.name] = e.target.value;
    values[name] = entry;

    setValues(name, {
      ...values[name],
    });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Add New Course
              </h3>
              <div className="space-y-6">
                <Formik
                  initialValues={initialState}
                  validate={validate}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const payload = {
                      ...values,
                    };
                    setSubmitting(true);
                    try {
                      const response = await createCoursesApi(payload);
                      triggerSuccess(addToast, response?.message);
                      setSubmitting(false);
                      handleModalClose();
                      handleFetchData();
                    } catch (error) {
                      triggerError(addToast, error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form className="" onSubmit={handleSubmit}>
                      <div className="w-full mb-6">
                        <InputGroup
                          type="text"
                          placeholder="Course Title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.title && touched.title && errors.title}
                        />
                      </div>

                      <div className="w-full rounded-lg bg-primary-light px-4 py-3 text-primary font-semibold">
                        <p>Enrol Link</p>
                      </div>

                      <div className="mt-6 mb-6">
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Enrol link URL"
                            name="enrolLink"
                            value={values.enrolLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="w-full rounded-lg bg-primary-light px-4 py-3 text-primary font-semibold">
                        <p>Payment In Naira (₦)</p>
                      </div>

                      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Outright Amount e.g 200,000"
                            name="outRightPaymentAmount"
                            value={values.paymentInNaira.outRightPaymentAmount}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInNaira",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Outright Link"
                            name="outRightPaymentLink"
                            value={values.paymentInNaira.outRightPaymentLink}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInNaira",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Upfront(%) e.g 70%"
                            name="partPaymentPercentageUpfront"
                            value={
                              values.paymentInNaira.partPaymentPercentageUpfront
                            }
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInNaira",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Amount e.g 200,000"
                            name="partPaymentAmount"
                            value={values.paymentInNaira.partPaymentAmount}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInNaira",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Link"
                            name="partPaymentLink"
                            value={values.paymentInNaira.partPaymentLink}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInNaira",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-6 w-full rounded-lg bg-primary-light px-4 py-3 text-primary font-semibold">
                        <p>Payment In Dollars ($)</p>
                      </div>

                      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Outright Amount e.g 1,200"
                            name="outRightPaymentAmount"
                            value={values.paymentInEuro.outRightPaymentAmount}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInEuro",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Outright Link"
                            name="outRightPaymentLink"
                            value={values.paymentInEuro.outRightPaymentLink}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInEuro",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Upfront(%) e.g 70%"
                            name="partPaymentPercentageUpfront"
                            value={
                              values.paymentInEuro.partPaymentPercentageUpfront
                            }
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInEuro",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Amount e.g 699"
                            name="partPaymentAmount"
                            value={values.paymentInEuro.partPaymentAmount}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInEuro",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputGroup
                            type="text"
                            placeholder="Part Payment Link"
                            name="partPaymentLink"
                            value={values.paymentInEuro.partPaymentLink}
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "paymentInEuro",
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end mt-6 mb-3">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="py-3 px-10 bg-primary text-white rounded-md my-3 focus:outline-none mr-4"
                        >
                          {isSubmitting ? "Processing..." : "Submit"}
                        </button>
                        <button
                          type="button"
                          onClick={handleModalClose}
                          className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddNewCourseModal;
