import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import DeleteModal from "../../../components/modals/DeleteModal";
import {
  deleteStoryApi,
  getStoriesApi,
} from "../../../store/actions/storiesActions";
import AdminMainWrapper from "../layouts/AdminMainWrapper";
import AdminEditJobModal from "./AdminEditJobModal";
import AdminStoryDetailsModal from "./AdminStoryDetailsModal";

const AdminStories = () => {
  const { addToast } = useToasts();

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Company Role",
      selector: "companyRole",
      sortable: true,
    },

    {
      name: "Status",
      selector: "approve",
      sortable: true,
      cell: (row) => (
        <div>
          {row?.approve ? (
            <p className="text-green-500 text-sm-15">Approved</p>
          ) : (
            <p className="text-yellow-500">Awaiting Approval</p>
          )}
        </div>
      ),
    },

    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <div>{new Date(row.createdAt)?.toDateString()}</div>,
    },

    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <div className="flex">
          <button
            className="focus:outline-none text-blue mr-3"
            onClick={() => handleShowApproveOrDisapproveModal(row)}
          >
            {row?.approve ? "Disapprove" : "Approve"}
          </button>
          <button
            className="focus:outline-none text-red-600"
            onClick={() => handleShowDeleteModal(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setpageLimit] = useState(10);

  const [selectedRow, setSelectedRow] = useState("");
  const [showModal, setShowModal] = useState(false);

  const fetchData = async (page, limit) => {
    setLoading(true);
    try {
      const params = {
        page,
        limit,
      };

      const response = await getStoriesApi(params);
      setStories(response.data);
      setTotalRows(response.count);
      setLoading(false);
    } catch (error) {
      triggerError(addToast, error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, pageLimit);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchData(page, newPerPage);
    setpageLimit(newPerPage);
  };

  const handleViewSelectedRow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedRow("");
    setShowModal(false);
  };

  const [approveOrDisapproveModal, setApproveOrDisapproveModal] = useState(
    false
  );
  const handleShowApproveOrDisapproveModal = (row) => {
    setSelectedRow(row);
    setApproveOrDisapproveModal(true);
  };

  const handleCloseApproveOrDisapproveModal = () => {
    setSelectedRow("");
    setApproveOrDisapproveModal(false);
  };

  const handleUpdateData = (id, data) => {
    setStories(stories.map((a) => (a._id === id ? data : a)));
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleShowDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedRow("");
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await deleteStoryApi(selectedRow?._id);
      setStories(stories.filter((a) => a._id !== selectedRow?._id));
      triggerSuccess(addToast, response?.message);
      setDeleteLoading(false);
      handleCloseDeleteModal();
    } catch (error) {
      triggerError(addToast, error);
      setDeleteLoading(false);
    }
  };

  return (
    <AdminMainWrapper>
      <div className="">
        <p className="text-gray-2 text-sm-15 font-">
          <Link to="/dashboard">Dashboard</Link> &gt;{" "}
          <span className="text-gray font-semibold">Careers</span>
        </p>

        <div className="pt-6 flex flex-1 justify-between flex-row items-center">
          <h3 className="text-primary font-bold text-2xl">All Stories</h3>
        </div>

        <div className="mt-8 rounded-xl">
          <DataTable
            noHeader={true}
            columns={columns}
            data={stories}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            progressComponent={<div className="py-32 h4">Loading...</div>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleViewSelectedRow}
          />
        </div>
      </div>

      {showModal && (
        <AdminStoryDetailsModal
          show={showModal}
          handleModalClose={handleModalClose}
          data={selectedRow}
        />
      )}

      {approveOrDisapproveModal && (
        <AdminEditJobModal
          show={approveOrDisapproveModal}
          handleModalClose={handleCloseApproveOrDisapproveModal}
          data={selectedRow}
          handleUpdateData={handleUpdateData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleModalClose={handleCloseDeleteModal}
          title="Story"
          deleteLoading={deleteLoading}
          handleDelete={handleDelete}
        />
      )}
    </AdminMainWrapper>
  );
};

export default AdminStories;
