import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BusinessIcon from "../../../assets/images/svg/business.svg";
import ImpactIcon from "../../../assets/images/svg/intensive-learning.svg";
import Logo from "../../../assets/images/svg/logo/utivacolored.svg";
import PersonIcon from "../../../assets/images/svg/person.svg";
import NavDropdownTitle from "../../blocks/NavDropdownTitle";
import TestimonyLink from "../../blocks/TestimonyLink";
import { aboutMenuData } from "../data/aboutMenuData";
import { growData } from "../data/enterpriseMenuData";
import { schoolsMenuData } from "../data/schoolsMenuData";

const Menu = ({ name, handleClickMenu }) => {
  return (
    <button
      onClick={handleClickMenu}
      className="-m-3 py-3 pr-5 flex items-center justify-between focus:outline-none"
    >
      <span className="ml-3 text-sm-15 text-default">{name}</span>
      <span>
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-0.5"
            x2="7.85972"
            y2="-0.5"
            transform="matrix(0.763386 -0.645942 0.763386 0.645942 1 12)"
            stroke="#1E2027"
          />
          <line
            y1="-0.5"
            x2="9.16967"
            y2="-0.5"
            transform="matrix(0.763386 0.645942 -0.763386 0.645942 0 1)"
            stroke="#1E2027"
          />
        </svg>
      </span>
    </button>
  );
};

const DefaultMenu = ({ handleClickMenu }) => {
  return (
    <div className="mt-6">
      <div className="mb-2">
        <img src={BusinessIcon} alt="" />
      </div>
      <p className="pb-8 text-base text-gray">Utiva - The future of learning</p>
      <nav className="grid gap-y-8 mt-5">
        <Menu
          name="Schools"
          handleClickMenu={() => handleClickMenu("schools")}
        />
        <Menu
          name="Corporate"
          handleClickMenu={() => handleClickMenu("enterprise")}
        />
        <Menu name="Company" handleClickMenu={() => handleClickMenu("about")} />
      </nav>
    </div>
  );
};

const Arrow = () => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66227 6.19888C8.91611 5.94504 8.91611 5.53348 8.66227 5.27964L4.52569 1.14306C4.27185 0.889223 3.8603 0.889223 3.60646 1.14306C3.35262 1.3969 3.35262 1.80846 3.60646 2.0623L7.28341 5.73926L3.60646 9.41621C3.35262 9.67005 3.35262 10.0816 3.60646 10.3355C3.8603 10.5893 4.27185 10.5893 4.52569 10.3355L8.66227 6.19888ZM0.464844 6.38926H8.20265V5.08926H0.464844V6.38926Z"
      fill="#565656"
    />
  </svg>
);

const SubMenu = ({ selectedMenu, handleGoBack }) => {
  const items =
    selectedMenu === "schools"
      ? schoolsMenuData
      : selectedMenu === "enterprise"
      ? growData
      : selectedMenu === "about"
      ? aboutMenuData
      : "";

  return (
    <div className="mt-6">
      <button className="mb-8 focus:outline-none" onClick={handleGoBack}>
        <svg
          width="18"
          height="14"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.469669 5.46967C0.176776 5.76256 0.176776 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM16 5.25L0.999999 5.25V6.75L16 6.75V5.25Z"
            fill="#050794"
          />
        </svg>
      </button>
      <p className="pb-2 text-xl text-gray-2">
        {selectedMenu === "schools" ? (
          <NavDropdownTitle icon={PersonIcon} title="Utiva for Individuals" />
        ) : selectedMenu === "enterprise" ? (
          <NavDropdownTitle icon={ImpactIcon} title="Utiva for Enterprise" />
        ) : selectedMenu === "about" ? (
          <NavDropdownTitle icon={BusinessIcon} title="Utiva as A Company" />
        ) : (
          ""
        )}
      </p>
      <nav className="grid grid-cols-1 mb-4 -mt-4">
        {items?.length > 0 &&
          items.map((d) => (
            <div id={d.id} className="py-4 text-sm">
              {d?.url?.includes("http") ? (
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href={d?.url}
                  className="flex justify-between"
                >
                  {d?.title} <Arrow />
                </a>
              ) : (
                <Link to={d?.url} className="flex justify-between">
                  {d?.title} <Arrow />
                </Link>
              )}
            </div>
          ))}
      </nav>
    </div>
  );
};

const MobileMenu = ({ openMenu, handleCloseMenu }) => {
  const [menuView, setMenuView] = useState("default");

  const handleClickMenu = (menu) => {
    setMenuView(menu);
  };

  const handleClose = () => {
    setMenuView("default");
    handleCloseMenu();
  };

  const handleGoBack = () => {
    setMenuView("default");
  };

  return (
    <Transition
      show={openMenu}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      {(ref) => (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-4 w-auto" src={Logo} alt="Utiva" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {menuView === "default" ? (
                <DefaultMenu handleClickMenu={handleClickMenu} />
              ) : (
                <SubMenu selectedMenu={menuView} handleGoBack={handleGoBack} />
              )}
            </div>

            {menuView === "default" ? (
              <div className="py-8 px-5 space-y-6 relative">
                <div className="pb-6">
                  <Link
                    to="/school"
                    className="w-full flex items-center justify-center px-4 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-secondary"
                  >
                    Get Started
                  </Link>
                  <p className="mt-8 text-center text-sm lg:text-base text-gray">
                    Have an ongoing course?{" "}
                    <a
                      href="https://study.utiva.co#/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-semibold"
                    >
                      Login
                    </a>
                  </p>
                </div>

                <div className="pb-8 relative">
                  <TestimonyLink
                    justifyClassName="lg:justify-start"
                    className={"mt-4 lg:mt-6 xl:mt-10"}
                  />
                </div>
              </div>
            ) : (
              <div className="pb-10 px-5 relative">
                <TestimonyLink
                  justifyClassName="lg:justify-start"
                  className={"mt-4 lg:mt-6 xl:mt-10"}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Transition>
  );
};

export default MobileMenu;
