import React from "react";

export const CheckMarkIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M6.77788 14.3956C3.06768 14.3956 0.0487061 11.3767 0.0487061 7.66649C0.0487061 3.95629 3.06768 0.937317 6.77788 0.937317C8.06804 0.937317 9.32326 1.30494 10.4079 2.0011C10.6208 2.13821 10.6832 2.42205 10.5461 2.63554C10.4085 2.84904 10.1253 2.91076 9.91177 2.77377C8.97579 2.17237 7.89184 1.85493 6.77788 1.85493C3.57353 1.85493 0.966315 4.46214 0.966315 7.66649C0.966315 10.8707 3.57353 13.4779 6.77788 13.4779C9.98212 13.4779 12.5893 10.8707 12.5893 7.66649C12.5893 7.47495 12.5801 7.28531 12.5624 7.09881C12.538 6.84611 12.7227 6.62219 12.9748 6.59778C13.228 6.57448 13.4513 6.75807 13.4758 7.0101C13.4965 7.22606 13.5069 7.44504 13.5069 7.66649C13.5069 11.3767 10.4881 14.3956 6.77788 14.3956Z"
          fill="black"
        />
        <path
          d="M7.54246 9.19587C7.42507 9.19587 7.30757 9.15118 7.2183 9.06134L4.46547 6.30852C4.28625 6.12918 4.28625 5.83862 4.46547 5.6594C4.64469 5.48018 4.93525 5.48018 5.11447 5.6594L7.54313 8.08806L13.9474 1.68373C14.1267 1.50451 14.4173 1.50451 14.5965 1.68373C14.7757 1.86295 14.7757 2.15351 14.5965 2.33273L7.8673 9.0619C7.77735 9.15118 7.65996 9.19587 7.54246 9.19587Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="14.6822"
            height="14.6822"
            fill="white"
            transform="translate(0.0487061 0.334747)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
