import React from "react";
import { Helmet } from "react-helmet";
import AffiliatesKidsHeader from "./components/AffiliatesKidsHeader";
import JoinBg from "../../assets/images/png/joinBg.webp";
import * as Animate from "react-reveal";
import Footer from "../../components/layout/Footer";

const AffiliatesKids = () => {
  return (
    <div>
      <Helmet>
        <title>Utiva | Affiliates for Kids</title>
      </Helmet>

      <AffiliatesKidsHeader />

      <section
        id="readyToGrowForm"
        className="faq-question-section mt-44 mb-48"
      >
        <div className="relative container mx-auto px-4 flex flex-wrap">
          <Animate.Fade bottom>
            <div className="w-full lg:w-6/12 faq-form-img relative flex-shrink">
              <img src={JoinBg} width="100%" className="object-cover" alt="" />
            </div>
            <div className="faq-form w-11/12 lg:w-6/12 p-7 md:p-12 lg:p-14 xl:p-20 relative lg:-ml-52 -mt-52 lg:mt-20 mx-auto lg:mx-0">
              <h4 className="font-semibold text-primary text-lg md:text-xl mb-3">
                How does making N5000 - N8000 per child that you bring to our
                school sounds? 
              </h4>
              <p className="font-light text-sm md:text-base tracking-widest mb-3">
                Each child gets to learn UI/UX Design, Web Development and App
                Development from us. 
              </p>
              <div className="relative w-full">
                <iframe
                  title="Affiliate form"
                  width="100%"
                  height="700rem"
                  src="https://forms.aweber.com/form/75/820123275.htm"
                />
              </div>
            </div>
          </Animate.Fade>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AffiliatesKids;
