import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.043 6.92427C22.5 8.70627 22.5 12.4263 22.5 12.4263C22.5 12.4263 22.5 16.1463 22.043 17.9283C21.789 18.9133 21.046 19.6883 20.105 19.9503C18.396 20.4263 12.5 20.4263 12.5 20.4263C12.5 20.4263 6.607 20.4263 4.895 19.9503C3.95 19.6843 3.208 18.9103 2.957 17.9283C2.5 16.1463 2.5 12.4263 2.5 12.4263C2.5 12.4263 2.5 8.70627 2.957 6.92427C3.211 5.93927 3.954 5.16427 4.895 4.90227C6.607 4.42627 12.5 4.42627 12.5 4.42627C12.5 4.42627 18.396 4.42627 20.105 4.90227C21.05 5.16827 21.792 5.94227 22.043 6.92427ZM10.5 15.9263L16.5 12.4263L10.5 8.92627V15.9263Z"
        fill="#050794"
      />
    </svg>
  );
};

export default YoutubeIcon;
