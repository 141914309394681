import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.42627C6.477 2.42627 2 6.90327 2 12.4263C2 17.4173 5.657 21.5543 10.438 22.3053V15.3163H7.898V12.4263H10.438V10.2233C10.438 7.71727 11.93 6.33327 14.215 6.33327C15.309 6.33327 16.453 6.52827 16.453 6.52827V8.98827H15.193C13.95 8.98827 13.563 9.75927 13.563 10.5503V12.4263H16.336L15.893 15.3163H13.563V22.3053C18.343 21.5553 22 17.4163 22 12.4263C22 6.90327 17.523 2.42627 12 2.42627Z"
        fill="#050794"
      />
    </svg>
  );
};

export default FacebookIcon;
