import axios from "../../axios/axios";

export const getJobsApi = async (params) => {
  try {
    const response = await axios.get("jobs", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createJobsApi = async (payload) => {
  try {
    const response = await axios.post("jobs", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateJobsApi = async (jobId, payload) => {
  try {
    const response = await axios.put(`jobs/${jobId}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteJobsApi = async (jobId) => {
  try {
    const response = await axios.delete(`jobs/${jobId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
