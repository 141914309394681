import React from "react";
import * as Animate from "react-reveal";
import AvatarImg1 from "../../../assets/images/png/avatars/1.webp";
import AvatarImg2 from "../../../assets/images/png/avatars/2.webp";
import AvatarImg3 from "../../../assets/images/png/avatars/3.webp";

const NucleusCTA = () => {
  return (
    <section className="pools-of-talents-section py-12 md:py-16 lg:py-24">
      <div className="container mx-auto px-4 w-full md:w-11/12 lg:w-10/12">
        <div className="pt-10 lg:pt-0 relative text-center w-full lg:w-5/12 mx-auto">
          <Animate.Fade bottom>
            <h4 className="text-base lg:text-xl xl:text-2xl font-mono font-semibold capitalize text-primary">
              All you need to start a career in tech now at your fingertips,
              literally!
            </h4>
          </Animate.Fade>
        </div>

        <div className="mx-auto flex flex-wrap justify-center py-9 mt-8">
          <Animate.Zoom>
            <div className="flex-shrink">
              <p className="bg-white absolute md:relative -mt-12 md:-mt-6  shadow-lg text-xs md:text-sm px-5 py-3 rounded-lg">
                Delivering excellence 🏆
              </p>
            </div>
            <div className="inline-flex flex-shrink mx-auto md:mx-0">
              <img
                src={AvatarImg1}
                className="h-14 w-14 md:h-20 md:w-20 object-cover"
                alt=""
              />
              <img
                src={AvatarImg2}
                className="relative -left-5 h-14 w-14 md:h-20 md:w-20 object-cover "
                alt=""
              />
              <img
                src={AvatarImg3}
                className="relative -left-10 h-14 w-14 md:h-20 md:w-20 object-cover "
                alt=""
              />
            </div>
            <div className="self-end absolute md:relative -mb-12 -mr-28 md:-mb-0 md:-mr-0 md:-left-10 flex-shrink">
              <p className="bg-white shadow-lg text-xs md:text-sm px-5 py-3 rounded-lg">
                I am skilled and effective 🚀
              </p>
            </div>
          </Animate.Zoom>
        </div>

        <div className="w-full md:w-11/12 lg:w-9/12 mt-14 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-auto">
            <Animate.Fade left>
              <div className="text-center bg-white shadow-lg py-12 px-4 rounded-md mb-10 md:mb-0 relative">
                <div
                  className="rounded-full py-2 w-28 absolute -top-5 mx-auto text-center left-28 md:left-36"
                  style={{
                    background: "#1619D3",
                    boxShadow: "0px 5.90854px 17.7256px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <p className="text-xs text-white">Cost Per Device</p>
                </div>
                <h4 className="text-primary font-bold text-3xl md:text-4xl pb-4">
                  25.25 <span className="text-2xl">USD</span>
                </h4>

                <p className="text-default text-base md:text-lg font-semibold pb-10">
                  Pay Dongle + Shipping
                </p>

                <div className="pb-8">
                  <ul className="space-y-3">
                    <li>300G Size in the Dongle</li>
                    <li>Shipping Partner Locally</li>
                    <li>Device Contains 40 Courses</li>
                  </ul>
                </div>
              </div>
            </Animate.Fade>

            <Animate.Fade right>
              <div className="text-center bg-white shadow-lg py-12 px-4 rounded-md relative">
                <div
                  className="rounded-full py-2 w-32 absolute -top-5 mx-auto text-center left-28 md:left-36"
                  style={{
                    background: "#1619D3",
                    boxShadow: "0px 5.90854px 17.7256px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <p className="text-xs text-white">Revenue Per Device</p>
                </div>
                <h4 className="text-primary font-bold text-3xl md:text-4xl pb-4">
                  80 <span className="text-2xl">USD</span>
                </h4>

                <p className="text-default text-base md:text-lg font-semibold pb-10">
                  Per Dongle + Access to 1 Course
                </p>

                <div className="pb-8">
                  <ul className="space-y-3">
                    <li>300G Dongle Space</li>
                    <li>Limited Access</li>
                    <li>Access 1 Course</li>
                  </ul>
                </div>
              </div>
            </Animate.Fade>
          </div>
        </div>

        <div className="mx-auto pt-20 pb-10" id="orderNow">
          <p className="text-center text-primary mb-6 text-xl font-semibold">
            Fill the form below to make an order
          </p>
          <iframe
            title="Nucleus Form"
            width="100%"
            height="550rem"
            src="https://forms.aweber.com/form/07/1461385507.htm"
          />
        </div>
      </div>
    </section>
  );
};

export default NucleusCTA;
