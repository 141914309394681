import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import store from "store";
import Logo from "../../../assets/images/svg/logo/utivacolored.svg";
import Logo2 from "../../../assets/images/svg/logo/utivawhite.svg";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import Button from "../../../components/elements/Button";
import InputGroup from "../../../components/elements/InputGroup";
import { resetPasswordApi } from "../../../store/actions";

const ResetPassword = ({ bgdark, ...props }) => {
  const { addToast } = useToasts();
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(props.location.pathname.slice(16));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = {
    password: "",
    retypePassword: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Password too short i.e minimum of 8";
    }

    if (!values.retypePassword) {
      errors.retypePassword = "Required";
    } else if (values.retypePassword.length < 8) {
      errors.retypePassword = "Password too short i.e minimum of 8";
    } else if (values.retypePassword !== values.password) {
      errors.retypePassword = "Password does not match";
    }

    return errors;
  };

  return (
    <div>
      <header className="pb-8 md:pb-24 w-full relative">
        <div className="relative bg-transparent pt-4 pb-4 z-10">
          <div
            className={`container mx-auto px-4 ${
              bgdark ? "text-white" : "text-primary"
            }`}
          >
            <div className="flex justify-between items-center py-6 md:justify-start">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/login">
                  <span className="sr-only">Utiva</span>
                  <img
                    className="h-6 w-auto sm:h-8"
                    src={bgdark ? Logo2 : Logo}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto shadow-xl rounded-lg w-4/12 px-10 py-6">
        <div>
          <h3 className="text-primary text-2xl pb-8 font-bold">
            Reset Password
          </h3>
          <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const payload = {
                password: values.password,
              };
              store.remove("utivav2_token");
              store.remove("utivav2_data");

              try {
                const response = await resetPasswordApi(payload, token);
                triggerSuccess(addToast, response.message);

                store.set("utivav2_token", response.token);
                store.set("utivav2_data", response.user);
                props.history.push("/dashboard");
                setSubmitting(false);
              } catch (error) {
                triggerError(addToast, error);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              isSubmitting,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="w-full mb-6">
                  <InputGroup
                    type="password"
                    placeholder="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.password && touched.password && errors.password
                    }
                  />
                </div>

                <div className="w-full mb-6">
                  <InputGroup
                    type="password"
                    placeholder="Retype New Password"
                    name="retypePassword"
                    value={values.retypePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.retypePassword &&
                      touched.retypePassword &&
                      errors.retypePassword
                    }
                  />
                </div>

                <div className="w-full mb-8 text-right">
                  <Button
                    disabled={isSubmitting}
                    text={isSubmitting ? "Processing..." : "Reset Password"}
                  />
                </div>

                <div className="text-center text-gray">
                  <Link to="/forgot-password" className="text-primary">
                    &lt;&lt; Resend Email &gt;&gt;
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default withRouter(ResetPassword);
