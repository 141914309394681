import React from "react";

const NavDropdownTitle = ({ icon, title }) => {
  return (
    <div className="flex items-center space-x-4 mb-10">
      <div className="">
        <img src={icon} alt="Individual" />
      </div>
      <div className="mt-1">
        <p className="font-mono font-semibold text-primary text-base xl:text-lg">
          {title}
        </p>
      </div>
    </div>
  );
};

export default NavDropdownTitle;
