import React from "react";

const TextAreaGroup = ({
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  rows,
  cols,
  className,
  ...props
}) => {
  return (
    <>
      <textarea
        className={`w-full border border-gray-2 rounded-lg placeholder-temp-secondary text-sm sm:text-base px-4 py-4 sm:px-6 text-temp-secondary focus:outline-none focus:border focus:border-primary bg-temp-green-100 mb-2 ${
          className || ""
        }`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows || "4"}
        cols={cols}
        {...props}
      ></textarea>
      <span className="text-sm text-red-600">{error}</span>
    </>
  );
};

export default TextAreaGroup;
