import React from "react";
import * as Animate from "react-reveal";
import IloImg from "../../../assets/images/png/nucleus.jpeg";
import HeaderNavigation from "../../../components/layout/HeaderNavigation";
import Img1 from "../../../assets/images/png/partner-logo/enugutech.webp";
import Img2 from "../../../assets/images/png/partner-logo/hp.png";

const NucluesHeader = () => {
  return (
    <header className="student-loan-hero pb-0 md:pb-24 w-full relative mb-10">
      <HeaderNavigation />
      <div className="pt-2 md:pt-10 lg:pt-24 container mx-auto px-4">
        <div className="flex flex-wrap items-center">
          <div className=" pt-14 lg:pb-14 lg:mx-0">
            <Animate.Fade bottom>
              <h2 className="w-10/12 lg:w-8/12 text-4xl font-mono text-primary leading-normal font-semibold pb-6 lg:pb-10 text-center lg:text-left mx-auto lg:mx-0">
                We’ve made it cheaper!
              </h2>

              <p className="w-full lg:w-7/12 text-default lg:leading-relaxed text-lg lg:text-xl pb-10 text-center lg:text-left mx-auto lg:mx-0">
                Learn tech skills with zero internet. Introducing “The Nucleus’
                by Utiva
              </p>

              <div className="w-full relative -right-4 md:hidden mb-12">
                <div className="bg-primary py-14 px-6 mx-auto relative">
                  <div className="relative py-12">
                    <img
                      src={IloImg}
                      width="70%"
                      className="object-contain mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <p className="text-gray mb-2">Supported By:</p>
              <div className="w-full sm:w-10/12 md:w-7/12 lg:w-9/12 px-2 mx-auto lg:mx-0 lg:px-0 text-sm md:text-base flex flex-wrap lg:justify-start">
                <img src={Img1} alt="Enugu Tech" width="150rem" />
                <img src={Img2} alt="Enugu Tech" width="100rem" />
              </div>
            </Animate.Fade>
          </div>

          <div className="w-6/12 absolute right-0 hidden lg:block">
            <Animate.Fade right>
              <div className="bg-primary py-14 px-6  mx-auto relative">
                <div className="relative py-12">
                  <img
                    src={IloImg}
                    width="80%"
                    className="object-contain mx-auto rounded-"
                    alt=""
                  />
                </div>
              </div>
            </Animate.Fade>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NucluesHeader;
