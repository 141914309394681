import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const InputDatePicker = ({
  labelColor,
  placeholder,
  name,
  value,
  onChange,
  error,
  className,
  ...props
}) => {
  return (
    <>
      <DatePicker
        name={name}
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        selectsRange
        className={`w-full border border-gray-2 rounded-lg placeholder-gray-2 text-sm sm:text-base h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-primary focus:outline-none focus:border focus:border-primary bg-temp-green-100 mb-2 ${
          className || ""
        }`}
        {...props}
      />

      <span className="text-red-600 text-sm">{error}</span>
    </>
  );
};

export default InputDatePicker;
