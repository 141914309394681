import { Formik } from "formik";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Logo from "../../../assets/images/svg/logo/utivacolored.svg";
import Logo2 from "../../../assets/images/svg/logo/utivawhite.svg";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import Button from "../../../components/elements/Button";
import InputGroup from "../../../components/elements/InputGroup";
import { forgotPasswordApi } from "../../../store/actions";

const ForgotPassword = ({ bgdark, ...props }) => {
  const { addToast } = useToasts();

  const initialState = {
    email: "",
  };

  const validate = (values) => {
    const errors = {};

    if (
      !values.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Enter a valid email address";
    }

    return errors;
  };

  return (
    <div>
      <header className="pb-8 md:pb-24 w-full relative">
        <div className="relative bg-transparent pt-4 pb-4 z-10">
          <div
            className={`container mx-auto px-4 ${
              bgdark ? "text-white" : "text-primary"
            }`}
          >
            <div className="flex justify-between items-center py-6 md:justify-start">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/login">
                  <span className="sr-only">Utiva</span>
                  <img
                    className="h-6 w-auto sm:h-8"
                    src={bgdark ? Logo2 : Logo}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto shadow-xl rounded-lg w-4/12 px-10 py-6">
        <div>
          <h3 className="text-primary text-2xl pb-8 font-bold">
            Forgot Password
          </h3>

          <p className="text-gray py-4">
            Enter your email below and a reset link will be sent to you
          </p>
          <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const payload = {
                email: values.email,
              };

              try {
                const response = await forgotPasswordApi(payload);
                triggerSuccess(addToast, response.message);
                setSubmitting(false);
              } catch (error) {
                triggerError(addToast, error);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              isSubmitting,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="w-full mb-6">
                  <InputGroup
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email && errors.email}
                  />
                </div>

                <div className="w-full mb-8 text-right">
                  <Button
                    disabled={isSubmitting}
                    text={isSubmitting ? "Processing..." : "Submit"}
                  />
                </div>

                <div className="text-center text-gray">
                  Have an account?{" "}
                  <Link to="/login" className="text-primary">
                    Sign In
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default withRouter(ForgotPassword);
