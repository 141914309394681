import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import DeleteModal from "../../../components/modals/DeleteModal";
import {
  deleteCoursesApi,
  getCohortsApi,
  getCoursesApi,
} from "../../../store/actions";
import AdminMainWrapper from "../layouts/AdminMainWrapper";
import AdminAddNewCourseModal from "./AdminAddNewCourseModal";
import AdminCourseDetailsModal from "./AdminCourseDetailsModal";
import AdminEditCohortModal from "./AdminEditCohortModal";
import AdminEditCourseModal from "./AdminEditCourseModal";

const AdminCourses = () => {
  const { addToast } = useToasts();

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Enrol Link",
      selector: "enrolLink",
      sortable: true,
      cell: (row) => (
        <div className="flex">
          <a
            href={row.enrolLink}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-primary"
          >
            {row.enrolLink}
          </a>
        </div>
      ),
    },

    // {
    //   name: "₦ (Outright Amount)",
    //   selector: "paymentInNaira.outRightPaymentAmount",
    //   sortable: true,
    // },

    // {
    //   name: "₦ (Part Payment Amount)",
    //   selector: "paymentInNaira.partPaymentAmount",
    //   sortable: true,
    // },

    // {
    //   name: "$ (Outright Amount)",
    //   selector: "paymentInEuro.outRightPaymentAmount",
    //   sortable: true,
    // },

    // {
    //   name: "$ (Part Payment Amount)",
    //   selector: "paymentInEuro.partPaymentAmount",
    //   sortable: true,
    // },

    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <div className="flex">
          <button
            className="focus:outline-none text-blue mr-3"
            onClick={() => handleShowEditModal(row)}
          >
            Edit
          </button>
          <button
            className="focus:outline-none text-red-600"
            onClick={() => handleShowDeleteModal(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setpageLimit] = useState(10);

  const [selectedRow, setSelectedRow] = useState("");
  const [showModal, setShowModal] = useState(false);

  const fetchData = async (page, limit) => {
    setLoading(true);
    try {
      const params = {
        page,
        limit,
      };

      const response = await getCoursesApi(params);
      setCourses(response.data);
      setTotalRows(response.count);
      setLoading(false);
    } catch (error) {
      triggerError(addToast, error);
      setLoading(false);
    }
  };

  const [cohortData, setCohortData] = useState({});
  const [cohortLoading, setCohortLoading] = useState(false);
  const fetchCohortDateData = async () => {
    setCohortLoading(true);
    try {
      const params = { page: 1, limit: 1 };
      const response = await getCohortsApi(params);
      setCohortData(response?.data?.[0] || {});
      setCohortLoading(false);
    } catch (error) {
      setCohortLoading(false);
    }
  };

  useEffect(() => {
    fetchCohortDateData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, pageLimit);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchData(page, newPerPage);
    setpageLimit(newPerPage);
  };

  const handleViewSelectedRow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedRow("");
    setShowModal(false);
  };

  const [addNewCourseModal, setAddNewCourseModal] = useState(false);
  const handleAddNewCourseShowModal = () => {
    setAddNewCourseModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedRow("");
    setShowEditModal(false);
  };

  const handleUpdateData = (id, data) => {
    setCourses(courses.map((a) => (a._id === id ? data : a)));
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleShowDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedRow("");
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await deleteCoursesApi(selectedRow?._id);
      triggerSuccess(addToast, response?.message);
      setDeleteLoading(false);

      setCourses(courses.filter((a) => a._id !== selectedRow?._id));

      handleCloseDeleteModal();
    } catch (error) {
      triggerError(addToast, error);
      setDeleteLoading(false);
    }
  };

  const [showChangeCohortDateModal, setShowChangeCohortDateModal] = useState(
    false
  );
  const handleShowChangeCohortDateModal = () => {
    setShowChangeCohortDateModal(true);
  };

  const handleUpdateCohortData = (data) => {
    setCohortData(data);
  };

  return (
    <AdminMainWrapper>
      <div className="">
        <p className="text-gray-2 text-sm-15 font-">
          <Link to="/dashboard">Dashboard</Link> &gt;{" "}
          <span className="text-gray font-semibold">Courses</span>
        </p>

        <div className="pt-6">
          <h3 className="text-primary font-bold text-2xl">Utiva Courses</h3>

          <div className="mt-8 mb-8 rounded-lg px-5 pt-6 pb-8 w-full md:w-6/12 lg:w-5/12 xl:w-4/12 bg-white">
            <h4 className="text-secondary text-base font-medium">
              Next Cohort Start Date
            </h4>
            <p className="mt-3 text-default font-bold text-2xl">
              {cohortLoading ? "Loading..." : cohortData?.date || "Not Set"}
            </p>

            <div className="mt-4">
              <button
                onClick={handleShowChangeCohortDateModal}
                className="text-white text-sm bg-default font-semibold rounded-lg px-4 py-2 focus:outline-none"
              >
                Change Date &nbsp; &gt;
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleAddNewCourseShowModal}
            className="text-white text-sm-15 bg-secondary font-semibold rounded-lg px-6 py-3 focus:outline-none"
          >
            Add New Course
          </button>
        </div>

        <div className="mt-8 rounded-xl">
          <DataTable
            noHeader={true}
            columns={columns}
            data={courses}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            progressComponent={<div className="py-32 h4">Loading...</div>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleViewSelectedRow}
          />
        </div>
      </div>
      {showModal && (
        <AdminCourseDetailsModal
          show={showModal}
          handleModalClose={handleModalClose}
          data={selectedRow}
        />
      )}

      {addNewCourseModal && (
        <AdminAddNewCourseModal
          show={addNewCourseModal}
          handleModalClose={() => setAddNewCourseModal(false)}
          handleFetchData={fetchData}
        />
      )}

      {showEditModal && (
        <AdminEditCourseModal
          show={showEditModal}
          handleModalClose={handleCloseEditModal}
          data={selectedRow}
          handleUpdateData={handleUpdateData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleModalClose={handleCloseDeleteModal}
          title="Course"
          deleteLoading={deleteLoading}
          handleDelete={handleDelete}
        />
      )}

      {showChangeCohortDateModal && (
        <AdminEditCohortModal
          show={showChangeCohortDateModal}
          handleModalClose={() => {
            setShowChangeCohortDateModal(false);
          }}
          data={cohortData}
          handleUpdateData={handleUpdateCohortData}
        />
      )}
    </AdminMainWrapper>
  );
};

export default AdminCourses;
