import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import store from "store";
import BlogIcon from "../../../assets/images/svg/blog.svg";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";
import StoryIcon from "../../../assets/images/svg/certificate.svg";
import CareerIcon from "../../../assets/images/svg/curriculum-vitae.svg";
import CourseIcon from "../../../assets/images/svg/learning.svg";
import AdminMainWrapper from "../layouts/AdminMainWrapper";

const Box = ({ url, icon, title, desc }) => {
  return (
    <div className="flex flex-col px-8 py-10 rounded-lg bg-white shadow-xl h-full">
      <Link to={`/dashboard/${url}`}>
        <div
          className="rounded-full py-3 px-4 w-16"
          style={{ background: "#F0F5FF" }}
        >
          <img src={icon} className="h-10 w-12" alt="" />
        </div>
        <div className="pt-6">
          <h3 className="text-primary font-semibold text-xl pb-2">{title}</h3>
          <p className="text-gray text-sm-15 leading-7">{desc}</p>
        </div>
      </Link>
    </div>
  );
};

const Dashboard = (props) => {
  const [userdata, setUserdata] = useState({});
  useEffect(() => {
    if (store.get("utivav2_token")) {
      setUserdata(store.get("utivav2_data"));
    } else {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AdminMainWrapper>
      <div className="px-8 py-10 bg-primary w-full rounded-lg">
        <h2 className="text-gray-30 font-bold text-xl">Welcome Back,</h2>
        <h2 className="text-secondary font-bold text-2xl">{userdata?.name}</h2>
        <p className="text-sm text-gray-30 pt-3 ">
          Keep track of the contents on the Utiva website at your convenience.
          😉
        </p>
      </div>

      <div className="relative w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 mt-12 pb-20">
        <Box
          url="courses"
          icon={CourseIcon}
          title="Courses"
          desc="View and Manage all courses / bootcamps pricing and payment links on the website."
        />
        <Box
          url="stories"
          icon={StoryIcon}
          title="Stories"
          desc="View and Manage all uploaded testimonials from trained students on the website."
        />
        <Box
          url="events"
          icon={CalendarIcon}
          title="Events"
          desc="Add new articles and view all uploaded blog contents on the website."
        />
        <Box
          url="careers"
          icon={CareerIcon}
          title="Careers"
          desc="Add new articles and view all uploaded blog contents on the website."
        />
        <Box
          url="blogs"
          icon={BlogIcon}
          title="Blogs"
          desc="Add new articles and view all uploaded blog contents on the website."
        />
      </div>
    </AdminMainWrapper>
  );
};

export default withRouter(Dashboard);
