import React from "react";

export const MapIcon = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 0C3.36763 0 1.63281 1.73482 1.63281 3.86719C1.63281 4.58764 1.83238 5.29074 2.21008 5.90073L5.27987 10.8477C5.33867 10.9424 5.44227 11 5.55369 11C5.55455 11 5.55539 11 5.55625 11C5.66863 10.9991 5.77244 10.9398 5.83017 10.8433L8.82172 5.84848C9.17858 5.25138 9.36719 4.56627 9.36719 3.86719C9.36719 1.73482 7.63237 0 5.5 0ZM8.26861 5.51757L5.54881 10.0587L2.75788 5.56114C2.4435 5.05343 2.27305 4.46768 2.27305 3.86719C2.27305 2.09021 3.72303 0.640234 5.5 0.640234C7.27697 0.640234 8.7248 2.09021 8.7248 3.86719C8.7248 4.44987 8.56558 5.02064 8.26861 5.51757Z"
        fill="black"
      />
      <path
        d="M5.5 1.93359C4.43382 1.93359 3.56641 2.801 3.56641 3.86719C3.56641 4.92656 4.4197 5.80078 5.5 5.80078C6.59362 5.80078 7.43359 4.91492 7.43359 3.86719C7.43359 2.801 6.56618 1.93359 5.5 1.93359ZM5.5 5.16055C4.78549 5.16055 4.20664 4.57976 4.20664 3.86719C4.20664 3.1564 4.78921 2.57383 5.5 2.57383C6.21079 2.57383 6.79121 3.1564 6.79121 3.86719C6.79121 4.56936 6.22583 5.16055 5.5 5.16055Z"
        fill="black"
      />
    </svg>
  );
};
