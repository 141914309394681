import axios from "../../axios/axios";

export const getEventsApi = async (params) => {
  try {
    const response = await axios.get("events", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleEventsApi = async (eventId) => {
  try {
    const response = await axios.get(`events/${eventId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEventsApi = async (payload) => {
  try {
    const response = await axios.post("events", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEventsApi = async (eventId, payload) => {
  try {
    const response = await axios.put(`events/${eventId}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEventsApi = async (eventId) => {
  try {
    const response = await axios.delete(`events/${eventId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
