import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import CustomEditor from "../../../components/elements/CustomEditor";
import InputGroup from "../../../components/elements/InputGroup";
import SelectGroup from "../../../components/elements/SelectGroup";
import { updateBlogsApi } from "../../../store/actions";

const AdminEditBlogModal = ({
  show,
  handleModalClose,
  data,
  handleUpdateData,
  ...props
}) => {
  const { addToast } = useToasts();

  const [initialState, setInitialState] = useState({
    title: "",
    content: "",
    category: "",
    contentHeaderImage: "https://drive.google.com/thumbnail?sz=w1000&id=",
  });

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.content) {
      errors.content = "Article Content is required";
    }
    if (!values.category) {
      errors.category = "Article Category is required";
    }
    if (!values.contentHeaderImage) {
      errors.contentHeaderImage = "Article Content Header link is required";
    }

    return errors;
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Edit Blog Article
              </h3>
              {initialState?.title && (
                <div className="space-y-6">
                  <Formik
                    initialValues={initialState}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const payload = {
                        ...values,
                      };
                      setSubmitting(true);
                      try {
                        const response = await updateBlogsApi(
                          data._id,
                          payload
                        );
                        triggerSuccess(addToast, response?.message);
                        setSubmitting(false);
                        handleUpdateData(data._id, response?.data);
                        handleModalClose();
                      } catch (error) {
                        triggerError(addToast, error);
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form className="" onSubmit={handleSubmit}>
                        <div className="w-full mb-6">
                          <InputGroup
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.title && touched.title && errors.title
                            }
                          />
                        </div>

                        <div className="w-full mb-6">
                          <SelectGroup
                            placeholder="Article Category"
                            name="category"
                            value={values.category}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.category &&
                              touched.category &&
                              errors.category
                            }
                            title="Blog Category"
                            options={
                              <>
                                <option value="Data">Data</option>
                                <option value="Design">Design</option>
                                <option value="Product">Product</option>
                                <option value="HR Management">
                                  HR Management
                                </option>
                                <option value="Project Management">
                                  Project Management
                                </option>
                                <option value="Cloud Computing">
                                  Cloud Computing
                                </option>
                                <option value="Programming">Programming</option>
                              </>
                            }
                          />
                        </div>

                        <div className="w-full mb-6">
                          <p className="bg-primary-light text-primary py-2 px-6 text-xs mb-4 rounded-lg">
                            Append only the ID generated after making the image
                            public from google drive to the link in the field
                          </p>
                          <InputGroup
                            type="text"
                            placeholder="Article Header Image Link"
                            name="contentHeaderImage"
                            value={values.contentHeaderImage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.contentHeaderImage &&
                              touched.contentHeaderImage &&
                              errors.contentHeaderImage
                            }
                          />
                        </div>

                        <div className="w-full mb-6">
                          <label htmlFor="content" className="text-gray">
                            Article Content
                          </label>
                          <div className=" mt-2">
                            <p className="bg-primary-light text-primary py-2 px-6 text-xs mb-4 rounded-lg">
                              To add an image to the article content, copy this
                              snippet below and paste into the field box and
                              replace the ID to the image ID generated after
                              making the image public from google drive. <br />
                              <strong>
                                {`<img
                                src="https://drive.google.com/thumbnail?sz=w1000&id=INSERT_ID_HERE"
                                width="100%"
                                alt=""
                              />`}
                              </strong>{" "}
                            </p>
                            <CustomEditor
                              name="content"
                              value={values.content}
                              onChange={(e, editor) => {
                                const value = editor.getData();

                                setFieldValue("content", value);
                              }}
                              error={
                                errors.content &&
                                touched.content &&
                                errors.content
                              }
                            />
                          </div>
                        </div>

                        <div className="flex justify-end mb-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="py-3 px-10 bg-primary text-white rounded-md my-3 focus:outline-none mr-4"
                          >
                            {isSubmitting ? "Processing..." : "Update"}
                          </button>
                          <button
                            type="button"
                            onClick={handleModalClose}
                            className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditBlogModal;
