import React, { useEffect, useState } from "react";

import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/images/svg/logo/utivacolored.svg";
import Logo2 from "../../assets/images/svg/logo/utivawhite.svg";

import useFBQPageView from "../../helpers/useFBQPageView";
import useSelectedLocation from "../../helpers/useSelectedLocation";
import { HamburgerIcon } from "../svgs";
import AboutDropdown from "./dropdowns/AboutDropdown";
import EnterpriseDropdown from "./dropdowns/EnterpriseDropdown";
import SchoolsDropdown from "./dropdowns/SchoolsDropdown";
import MobileMenu from "./mobileNav/MobileMenu";

const HeaderNavigation = ({ bgdark, ...props }) => {
  //   Location
  const selectedLocation = useSelectedLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isEnterpriseOpen, setIsEnterpriseOpen] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
    setIsAboutOpen(false);
    setIsEnterpriseOpen(false);
  };

  const handleAboutOpen = () => {
    setIsAboutOpen(!isAboutOpen);
    setIsOpen(false);
    setIsEnterpriseOpen(false);
  };

  const handleEnterpriseOpen = () => {
    setIsEnterpriseOpen(!isEnterpriseOpen);
    setIsOpen(false);
    setIsAboutOpen(false);
  };

  const handleCloseMenu = () => {
    setOpenMenu(!openMenu);
    setIsAboutOpen(false);
    setIsOpen(false);
    setIsEnterpriseOpen(false);
  };

  useEffect(() => {
    handleCloseMenu();
    setOpenMenu(false);
    setIsEnterpriseOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  const handleClose = () => {
    handleCloseMenu();
  };

  //   Facebook Pixel
  useFBQPageView();

  return (
    <div className="relative bg-transparent z-10">
      {/* <HeaderContinentSelectSection /> */}
      <div
        className={`container mx-auto px-4 pt-1 pb-4 ${
          bgdark ? "text-white" : "text-default"
        }`}
      >
        <div className="flex justify-between items-center py-6 md:justify-start">
          <div className="hidden sm-hidden md:block lg:hidden xl:hidden">
            <button
              type="button"
              onClick={handleCloseMenu}
              className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              aria-expanded="false"
            >
              <span className="sr-only">Open menu</span>

              <HamburgerIcon />
            </button>
          </div>
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Utiva</span>
              <img
                className="h-6 w-auto sm:h-8"
                src={bgdark ? Logo2 : Logo}
                alt=""
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden lg:hidden">
            <button
              type="button"
              onClick={handleCloseMenu}
              className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              aria-expanded="false"
            >
              <span className="sr-only">Open menu</span>

              <HamburgerIcon />
            </button>
          </div>
          <nav className="hidden lg:flex space-x-10">
            <div className="relative">
              <button
                type="button"
                onClick={handleOpen}
                className={`group inline-flex items-center text-base  focus:outline-none ${
                  bgdark ? "hover:text-secondary" : "hover:text-gray"
                }`}
                aria-expanded="false"
              >
                <span>Schools</span>

                <svg
                  className=" ml-2 h-5 w-5 group-hover:text-gray"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <SchoolsDropdown
                isOpen={isOpen}
                handleClose={handleClose}
                selectedLocation={selectedLocation}
              />
            </div>

            <div className="relative">
              <button
                type="button"
                onClick={handleEnterpriseOpen}
                className={`group inline-flex items-center text-base  focus:outline-none ${
                  bgdark ? "hover:text-secondary" : "hover:text-gray"
                }`}
                aria-expanded="false"
              >
                <span>Corporate</span>

                <svg
                  className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <EnterpriseDropdown
                isEnterpriseOpen={isEnterpriseOpen}
                handleClose={handleClose}
              />
            </div>

            <div className="relative">
              <button
                type="button"
                onClick={handleAboutOpen}
                className={`group inline-flex items-center text-base  focus:outline-none ${
                  bgdark ? "hover:text-secondary" : "hover:text-gray"
                }`}
                aria-expanded="false"
              >
                <span>Company</span>

                <svg
                  className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <AboutDropdown
                isAboutOpen={isAboutOpen}
                handleClose={handleClose}
              />
            </div>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              to="/school"
              className="whitespace-nowrap text-base text-sm-15 text-white bg-secondary border border-secondary rounded-lg py-2 px-6"
            >
              Get started
            </Link>
            <a
              href="https://study.utiva.co#/login"
              target="_blank"
              rel="noreferrer noopener"
              className={`ml-6 whitespace-nowrap inline-flex items-center text-sm-15 justify-center px-8 py-2 border rounded-lg text-base ${
                bgdark
                  ? "bg-white border-white text-primary"
                  : "bg-transparent border-primary"
              } `}
            >
              Login
            </a>
          </div>
        </div>
      </div>

      {/* <!--
    Mobile menu, show/hide based on mobile menu state.
  --> */}
      <MobileMenu openMenu={openMenu} handleCloseMenu={handleCloseMenu} />
    </div>
  );
};

export default withRouter(HeaderNavigation);
