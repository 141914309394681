/* eslint-disable eqeqeq */
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { triggerError, triggerSuccess } from "../../../components/alerts";
import InputDatePicker from "../../../components/elements/InputDatePicker";
import InputGroup from "../../../components/elements/InputGroup";
import SelectGroup from "../../../components/elements/SelectGroup";
import TextAreaGroup from "../../../components/elements/TextAreaGroup";
import { updateEventsApi } from "../../../store/actions";

const AdminEditEventModal = ({
  show,
  handleModalClose,
  data,
  handleUpdateData,
  ...props
}) => {
  const { addToast } = useToasts();

  const [initialState, setInitialState] = useState({
    title: "",
    eventShortDescription: "",
    eventLongDescription: "",
    eventLink: "",
    eventStartDate: "",
    eventEndDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventCategory: "",
    eventType: "",
    speakers: [
      {
        speakerName: "",
        speakerTitle: "",
        speakerBio: "",
        speakerImage: "https://drive.google.com/thumbnail?sz=w1000&id=",
      },
    ],
  });

  useEffect(() => {
    setInitialState({
      ...data,
      eventStartDate: new Date(data.eventStartDate),
      eventEndDate: new Date(data.eventEndDate),
      eventStartTime: new Date(data.eventStartTime),
      eventEndTime: new Date(data.eventEndTime),
    });
  }, [data]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Event title is required";
    }

    if (!values.eventLink) {
      errors.eventLink = "Event form link is required";
    }

    if (!values.eventStartDate) {
      errors.eventStartDate = "Event start date is required";
    }
    if (!values.eventEndDate) {
      errors.eventEndDate = "Event end date is required";
    }
    if (!values.eventStartTime) {
      errors.eventStartTime = "Event start time is required";
    }
    if (!values.eventEndTime) {
      errors.eventEndTime = "Event end time is required";
    }

    if (!values.eventCategory) {
      errors.eventCategory = "Event category is required";
    }

    if (!values.eventType) {
      errors.eventType = "Event type is required";
    }
    if (!values.eventShortDescription) {
      errors.eventShortDescription = "Event short description is required";
    }
    if (!values.eventLongDescription) {
      errors.eventLongDescription = "Event full details is required";
    }

    return errors;
  };

  const handleInputChange = (e, key, name, values, setValues) => {
    e.preventDefault();
    const entry = values[key];
    entry[e.target.name] = e.target.value;
    values[key] = entry;

    setValues(name, [...values]);
  };

  const removeSpeaker = (key, name, values, setValues) => {
    let newData = [];
    newData = values?.filter((d, index) => index != key);
    setValues(name, newData);
  };

  const addMoreSpeaker = (e, name, values, setValues) => {
    e.preventDefault();
    values.push({
      speakerName: "",
      speakerTitle: "",
      speakerBio: "",
      speakerImage: "https://drive.google.com/thumbnail?sz=w1000&id=",
    });

    setValues(name, [...values]);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Edit Event
              </h3>
              {initialState?.title && (
                <div className="space-y-6">
                  <Formik
                    initialValues={initialState}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const payload = {
                        ...values,
                      };
                      setSubmitting(true);
                      try {
                        const response = await updateEventsApi(
                          data._id,
                          payload
                        );
                        triggerSuccess(addToast, response?.message);
                        setSubmitting(false);
                        handleUpdateData(data._id, response?.data);
                        handleModalClose();
                      } catch (error) {
                        triggerError(addToast, error);
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form className="" onSubmit={handleSubmit}>
                        <div className="w-full mb-6">
                          <InputGroup
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.title && touched.title && errors.title
                            }
                          />
                        </div>
                        <div className="w-full mb-6">
                          <InputGroup
                            type="text"
                            placeholder="Event Form Link"
                            name="eventLink"
                            value={values.eventLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.eventLink &&
                              touched.eventLink &&
                              errors.eventLink
                            }
                          />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                          <div className="w-full mb-6">
                            <InputDatePicker
                              placeholder="Event Start Date"
                              name="eventStartDate"
                              value={values.eventStartDate}
                              onChange={(e) => {
                                setFieldValue("eventStartDate", e[0]);
                              }}
                              autoComplete="off"
                              onBlur={handleBlur}
                              error={
                                errors.eventStartDate &&
                                touched.eventStartDate &&
                                errors.eventStartDate
                              }
                            />
                          </div>

                          <div className="w-full mb-6">
                            <InputDatePicker
                              placeholder="Event End Date"
                              name="eventEndDate"
                              value={values.eventEndDate}
                              onChange={(e) => {
                                setFieldValue("eventEndDate", e[0]);
                              }}
                              autoComplete="off"
                              onBlur={handleBlur}
                              error={
                                errors.eventEndDate &&
                                touched.eventEndDate &&
                                errors.eventEndDate
                              }
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                          <div className="w-full mb-6">
                            <InputDatePicker
                              placeholder="Event Start Time"
                              name="eventStartTime"
                              value={values.eventStartTime}
                              onChange={(e) => {
                                setFieldValue("eventStartTime", e);
                              }}
                              autoComplete="off"
                              onBlur={handleBlur}
                              error={
                                errors.eventStartTime &&
                                touched.eventStartTime &&
                                errors.eventStartTime
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </div>

                          <div className="w-full mb-6">
                            <InputDatePicker
                              placeholder="Event End Time"
                              name="eventEndTime"
                              value={values.eventEndTime}
                              onChange={(e) => {
                                setFieldValue("eventEndTime", e);
                              }}
                              autoComplete="off"
                              onBlur={handleBlur}
                              error={
                                errors.eventEndTime &&
                                touched.eventEndTime &&
                                errors.eventEndTime
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                          <div className="w-full mb-6">
                            <SelectGroup
                              placeholder="Event Category"
                              name="eventCategory"
                              value={values.eventCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.eventCategory &&
                                touched.eventCategory &&
                                errors.eventCategory
                              }
                              options={
                                <>
                                  <option value="Masterclass">
                                    Masterclass
                                  </option>
                                  <option value="Conference">Conference</option>
                                  <option value="Alumni Community Event">
                                    Alumni Community Event
                                  </option>
                                </>
                              }
                            />
                          </div>
                          <div className="w-full mb-6">
                            <SelectGroup
                              placeholder="Event Type"
                              name="eventType"
                              value={values.eventType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.eventType &&
                                touched.eventType &&
                                errors.eventType
                              }
                              options={
                                <>
                                  <option value="Free for All">
                                    Free for All
                                  </option>
                                  <option value="Strictly by Invitation">
                                    Strictly by Invitation
                                  </option>
                                </>
                              }
                            />
                          </div>
                        </div>

                        <div className="w-full mb-6">
                          <TextAreaGroup
                            placeholder="Short Description"
                            name="eventShortDescription"
                            value={values.eventShortDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.eventShortDescription &&
                              touched.eventShortDescription &&
                              errors.eventShortDescription
                            }
                            maxLength="250"
                            max="250"
                            rows="3"
                          />
                        </div>

                        <div className="w-full mb-6">
                          <TextAreaGroup
                            placeholder="Event Full Details"
                            name="eventLongDescription"
                            value={values.eventLongDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.eventLongDescription &&
                              touched.eventLongDescription &&
                              errors.eventLongDescription
                            }
                            rows="8"
                          />
                        </div>

                        <div className="w-full rounded-lg bg-primary-light px-4 py-3 text-primary font-semibold">
                          <p>Add Event Speakers</p>
                        </div>

                        <div className="mt-6">
                          {values?.speakers?.map((data, index) => {
                            return (
                              <div className="space-y-6 mb-6" key={index}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                                  <div className="w-full mb-1">
                                    <InputGroup
                                      type="text"
                                      placeholder="Speaker Name"
                                      name="speakerName"
                                      value={data.speakerName}
                                      onChange={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "speakers",
                                          values.speakers,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="w-full mb-1">
                                    <InputGroup
                                      type="text"
                                      placeholder="Speaker Title e.g Data Analyst"
                                      name="speakerTitle"
                                      value={data.speakerTitle}
                                      onChange={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "speakers",
                                          values.speakers,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="w-full mb-6">
                                  <p className="bg-primary-light text-primary py-2 px-6 text-xs mb-4 rounded-lg">
                                    Append only the ID generated after making
                                    the image public from google drive to the
                                    link in the field
                                  </p>
                                  <InputGroup
                                    type="text"
                                    placeholder="Speaker Image Link"
                                    name="speakerImage"
                                    value={data.speakerImage}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e,
                                        index,
                                        "speakers",
                                        values.speakers,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                </div>

                                <div className="w-full mb-6">
                                  <TextAreaGroup
                                    placeholder="Short Bio"
                                    name="speakerBio"
                                    value={data.speakerBio}
                                    onChange={(e) => {
                                      handleInputChange(
                                        e,
                                        index,
                                        "speakers",
                                        values.speakers,
                                        setFieldValue
                                      );
                                    }}
                                    rows="3"
                                  />
                                </div>

                                {values.speakers.length > 1 || index == 1 ? (
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      className="py-2 px-6 rounded-md bg-red-400 focus:outline-none"
                                      onClick={() =>
                                        removeSpeaker(
                                          index,
                                          "speakers",
                                          values.speakers,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <i className="fa fa-trash-alt"></i> Remove
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <hr className="my-1 border-t border-light-blue" />
                              </div>
                            );
                          })}
                        </div>

                        <div className="flex justify-start mt-4">
                          <button
                            className="py-2 px-6 rounded-md bg-secondary focus:outline-none"
                            onClick={(e) =>
                              addMoreSpeaker(
                                e,
                                "speakers",
                                values.speakers,
                                setFieldValue
                              )
                            }
                          >
                            <i className="">+</i> Add More
                          </button>
                        </div>

                        <div className="flex justify-end mb-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="py-3 px-10 bg-primary text-white rounded-md my-3 focus:outline-none mr-4"
                          >
                            {isSubmitting ? "Processing..." : "Update"}
                          </button>
                          <button
                            type="button"
                            onClick={handleModalClose}
                            className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditEventModal;
