import React from "react";
import * as Animate from "react-reveal";
import HeaderNavigation from "../../../components/layout/HeaderNavigation";
import { CheckMarkIcon } from "../../../components/svgs";

import LandingImage from "../../../assets/images/png/affImg.webp";

const AffiliatesKidsHeader = () => {
  return (
    <header className="pb-0 w-full relative ">
      <HeaderNavigation />

      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">
          <div className="w-full md:w-10/12 lg:w-5/12 pt-6 md:pt-14 pb-14">
            <Animate.Fade bottom>
              <h2 className="text-3xl md:text-4xl lg:text-5xl lg:leading-tight text-primary font-mono font-semibold pb-6 pt-10 w-full">
                Become A Partner and An Affiliate
              </h2>
            </Animate.Fade>

            <Animate.Fade bottom>
              <p className="text-default lg:leading-relaxed text-base md:text-lg lg:text-xl pb-10 w-full md:w-10/12">
                We are looking for associates that can help us drive this, get
                more parents to be excited about this while they earn some great
                commission for doing the great work. 
              </p>
            </Animate.Fade>

            <Animate.Fade bottom>
              <div className="w-full flex flex-wrap">
                <p className="inline-flex items-center mb-4 mr-6">
                  <CheckMarkIcon />
                  <span className="pl-3 text-gray">100% Flexible</span>
                </p>
                <p className="inline-flex items-center mb-4">
                  <CheckMarkIcon />
                  <span className="pl-3 text-gray">Get Commission</span>
                </p>
              </div>
            </Animate.Fade>
          </div>

          <Animate.Zoom>
            <div className="relative lg:hidden rounded-lg mb-6">
              <img
                src={LandingImage}
                width="100%"
                className="object-cover rounded-lg  mx-auto "
                alt=""
              />
            </div>
          </Animate.Zoom>

          <div className="w-7/12 hidden lg:block">
            <div className="pt-8 px-6 mx-auto relative">
              <div className="">
                <Animate.Zoom>
                  <img
                    src={LandingImage}
                    width="80%"
                    className="object-contain mx-auto px-2 py-4"
                    alt=""
                  />
                </Animate.Zoom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AffiliatesKidsHeader;
