import axios from "../../axios/axios";

export const getCoursesApi = async (params) => {
  try {
    const response = await axios.get("courses", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleCoursesApi = async (courseId) => {
  try {
    const response = await axios.get(`courses/${courseId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCoursesApi = async (payload) => {
  try {
    const response = await axios.post("courses", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCoursesApi = async (courseId, payload) => {
  try {
    const response = await axios.put(`courses/${courseId}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCoursesApi = async (courseId) => {
  try {
    const response = await axios.delete(`courses/${courseId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
