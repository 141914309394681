import React from "react";
import { Transition } from "@headlessui/react";

const Row = ({ title, value }) => {
  return (
    <>
      <div className="">
        <p className="font-semibold text-default pb-3">{title}</p>
        <p className="text-gray">{value}</p>
      </div>
    </>
  );
};

const AdminEventDetailsModal = ({ show, handleModalClose, data, ...props }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="text-right">
            <button
              onClick={handleModalClose}
              className="mt-4 mr-8 px-3 py-1 rounded-full text-lg focus:outline-none bg-white shadow-lg text-secondary"
            >
              x
            </button>
          </div>
          <div className="bg-white px-5 py-1 sm:px-12 sm:py-6">
            <div className="">
              <h3
                className="mb-6 leading-6 text-lg md:text-xl text-primary font-bold"
                id="modal-headline"
              >
                Event Details
              </h3>
              <div className="space-y-6">
                <Row title="Event Title" value={data.title} />

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="Event Form Link"
                  value={
                    <a
                      className="text-gray hover:text-secondary"
                      href={data.eventLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {data.eventLink}
                    </a>
                  }
                />

                <hr className="my-1 border-t border-light-blue" />

                <div className="grid grid-cols-2 gap-4">
                  <Row title="Event Category" value={data.eventCategory} />
                  <Row title="Event Type" value={data.eventType} />
                </div>

                <hr className="my-1 border-t border-light-blue" />

                <div className="grid grid-cols-2 gap-4">
                  <Row
                    title="Start Date"
                    value={new Date(data.eventStartDate)?.toLocaleDateString()}
                  />
                  <Row
                    title="End Date"
                    value={new Date(data.eventEndDate)?.toLocaleDateString()}
                  />
                </div>

                <hr className="my-1 border-t border-light-blue" />

                <div className="grid grid-cols-2 gap-4">
                  <Row
                    title="Start Time"
                    value={new Date(data.eventStartTime)?.toLocaleTimeString()}
                  />
                  <Row
                    title="End Time"
                    value={new Date(data.eventEndTime)?.toLocaleTimeString()}
                  />
                </div>

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="Short Description"
                  value={data.eventShortDescription}
                />

                <hr className="my-1 border-t border-light-blue" />

                <Row title="Full Details" value={data.eventLongDescription} />

                <hr className="my-1 border-t border-light-blue" />

                <Row
                  title="Speakers"
                  value={
                    <div className="flex flex-wrap">
                      {data.speakers?.length > 0 ? (
                        data.speakers.map((d) => (
                          <div
                            className="w-full md:w-6/12 lg:w-4/12 mb-4"
                            key={d._id}
                          >
                            <div className="mx-auto text-center">
                              <div className="h-20 w-20 rounded-full bg-secondary mb-2 mx-auto">
                                <img
                                  src={d.speakerImage}
                                  alt=""
                                  className="h-20 w-20 rounded-full object-cover"
                                />
                              </div>
                              <p className="text-default text-base pb-2">
                                {d.speakerName}
                              </p>
                              <p className="text-gray text-sm pb-2 italic">
                                {d.speakerTitle}
                              </p>
                              <p className="text-gray text-sm pb-2">
                                {d.speakerBio}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="py-6">No Speaker Added to this event</p>
                      )}
                    </div>
                  }
                />

                <div className="flex justify-end mb-3">
                  <button
                    onClick={handleModalClose}
                    className="py-3 px-10 bg-red-600 text-white rounded-md my-3 focus:outline-none"
                  >
                    Close
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEventDetailsModal;
